import { useEffect, useState } from 'react';


type UseLoadImageProps = (url: string, enabled: boolean) => { isLoading: boolean, isLoaded: boolean };

export const useLoadImage: UseLoadImageProps = (url: string, enabled = true) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  
  useEffect(() => {
    if (!enabled) {
      setIsLoaded(true);
      setIsLoading(false);
      return;
    }
    try {
      fetch(url).then(r => {
        if(r.status == 200) {
          setIsLoaded(true);
        }
        setIsLoading(false);
      });
    } catch (e) {
      setIsLoaded(false);
      setIsLoading(false);
    }
  }, [enabled,url]);

  return { isLoading, isLoaded};
};
