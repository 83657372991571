import { useMemo } from 'react';
import {
  useEnt,
  useFind,
  getEnts,
  useHistory,
  wsSendCommand,
} from './useHomeAssistantCore.js';

function useEntsWithDeviceClass(deviceClass) {
  const ids = useFind(/.+/);
  return useMemo(() => {
    if (!ids.length) {
      return [];
    }
    const ents = getEnts(ids);
    return ents.filter(e => e && e.attributes && e.attributes.device_class === deviceClass).map(e => e.entity_id);
  },[ids,deviceClass]);
}

function useSensorsWithDeviceClass(deviceClass) {
  const sensor_ids = useFind(/^sensor\./);
  return useMemo(() => {
    if (!sensor_ids.length) {
      return [];
    }
    const sensors = getEnts(sensor_ids);
    return sensors.filter(e => e.attributes.device_class === deviceClass).map(e => e.entity_id);
  },[sensor_ids,deviceClass]);
}

function useBinarySensorsWithDeviceClass(deviceClass) {
  const binary_sensor_ids = useFind(/^binary_sensor\./);
  return useMemo(() => {
    if (!binary_sensor_ids.length) {
      return [];
    }
    const binary_sensors = getEnts(binary_sensor_ids);
    return binary_sensors.filter(e => e.attributes.device_class === deviceClass).map(e => e.entity_id);
  },[binary_sensor_ids,deviceClass]);
}

function useRunnings() {
  return useBinarySensorsWithDeviceClass('running');
}

function useDoors() {
  return useBinarySensorsWithDeviceClass('door');
}

function useGarageDoors() {
  return useBinarySensorsWithDeviceClass('garage_door');
}

function useWindows() {
  return useBinarySensorsWithDeviceClass('window');
}

function useMotions() {
  return useBinarySensorsWithDeviceClass('motion');
}


function useLock(entity_id) {
  const ent = useEnt(entity_id);
  const toggle = () => {
    wsSendCommand({
      'type': 'call_service',
      'domain': 'lock',
      'service': (ent.state === 'locked') ? 'unlock' : 'lock',
      'target': {
        'entity_id': entity_id,
      },
    }, () => null);
  };
  return [ent, toggle];
}

function useLight(entity_id) {
  const ent = useEnt(entity_id);
  const toggle = () => {
    // console.log('toggle',{entity_id})
    wsSendCommand({
      'type': 'call_service',
      'domain': 'light',
      'service': (ent.state === 'off') ? 'turn_on' : 'turn_off',
      'target': {
        'entity_id': entity_id,
      },
    }, () => null);
  };
  const setBrightness = (n) => {
    // console.log('setBrightness',{entity_id,n})
    wsSendCommand({
      'type': 'call_service',
      'domain': 'light',
      'service': 'turn_on',
      'target': {
        'entity_id': entity_id,
      },
      'service_data': {
        'brightness': parseInt(n * 255 / 100),
      },
    }, () => null);
  };
  return [ent, toggle, setBrightness];
}

function useScene(entity_id) {
  const ent = useEnt(entity_id);
  const invokeScene = () => {
    wsSendCommand({
      'type': 'call_service',
      'domain': 'scene',
      'service': 'turn_on',
      'target': {
        'entity_id': entity_id,
      },
    }, (blob) => {
      console.log('result of scene call',blob);
    });
  };
  return [ent, invokeScene];
}

function getState(datum) {
  return datum?.state;
}

function useTimesToday(entity_id, stateGetter = getState) {
  const hist = useHistory(entity_id,0);
  const states = {};
  if (!hist) {
    return states;
  }

  let priorTime = hist.data[0].date.getTime();
  let priorState = stateGetter(hist.data[0]);
  for (let i=1;i<hist.data.length;i++) {
    const t = hist.data[i].date.getTime();
    const s = stateGetter(hist.data[i]);
    if (s === 'unavailable') {
      continue;
    }
    const dt = (t - priorTime) / 1000;
    if (states[priorState] === undefined) {
      states[priorState] = 0;
    }
    states[priorState] += dt;
    priorTime = t;
    priorState = s;
  }
  if (states[priorState] === undefined) {
    states[priorState] = 0;
  }
  states[priorState] += (Date.now() - priorTime) / 1000;
  return states;
}

export {
  useBinarySensorsWithDeviceClass,
  useSensorsWithDeviceClass,
  useEntsWithDeviceClass,
  useLight,
  useLock,
  useScene,
  useRunnings,
  useDoors,
  useWindows,
  useGarageDoors,
  useMotions,
  useTimesToday,
};
