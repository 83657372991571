import React, { FC, ReactNode } from 'react';
import styled from '@emotion/styled';

import { Title } from './Title';


const SectionLayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

interface SectionLayoutProps {
  children: ReactNode;
  title: string;
}

const Components = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  & > div {
    /*margin: 8px 12px 8px 0px;*/
    margin: 4px 8px 4px 0px;
  }
`;

export const SectionLayout: FC<SectionLayoutProps> = ({ children, title }) => {
  return (
    <SectionLayoutContainer>
      <Title>{title}</Title>
      <Components>
        {children}
      </Components>
    </SectionLayoutContainer>
  );
};
