import 'core-js';
import 'raf/polyfill'; // <-- added this line after installed packages

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Dash from './Dash';
import Tester from './Tester';
// import reportWebVitals from './reportWebVitals';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const skipStrict = true;

switch(document.location.pathname) {
  case '/':
    if (skipStrict) {
      root.render(<Dash />);
      break;
    }
    /* eslint-disable */
    root.render(
      <React.StrictMode>
        <Dash />
      </React.StrictMode>
    );
    break;
  case '/test':
    /* eslint-disable */
    root.render(
      <React.StrictMode>
        <Tester />
      </React.StrictMode>
    );
    break;
  case '/photo/':
  case '/photo':
    /* eslint-disable */
    root.render(
      <React.StrictMode>
        <div style={{color:'white',transformOrigin:'top right',transform:' translate(-100vh, 0)  rotate(270deg)',width:'100vh',height:'100vw',backgroundColor:'#330033'}}>
        Hello world
        <br/>
        <span style={{fontSize:20}}>Big text {(new Date()).toISOString()}</span>
        </div>
      </React.StrictMode>
    );
    break;
  case '/smol':
  default:
    /* eslint-disable */
    root.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>
    );
    break;
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
