// houseConfig

const exteriorAreaIds = [
  'garage',
  'front_yard',
  'front_porch',
  'balcony',
  'sunroom',
  'back_yard',
  'back_porch',
  'parking_area',
];

const bedroomAreaIds = [
  'parents_room',
  'alex_bedroom',
  'kate_bedroom',
  'zoe_room',
];


const ignoreMotionIds = [
  'binary_sensor.parking_area_motion',
  'binary_sensor.lower_driveway_motion',
];

const energyConsumers = {
  // 'Grid': {'sensor.powerwall_site_import':{color:'gray'}},
  // 'Load': {'sensor.powerwall_load_import':{color:'white'}},

  'Grid / Load': {
    'sensor.powerwall_site_import':{color:'gray'},
    'sensor.powerwall_load_import':{color:'white'},
  },

  // 'Solar': {'sensor.powerwall_solar_export':{color:'yellow'}},
  // 'Batt Disharge': {'sensor.powerwall_battery_export':{color:'green'}},

  'Solar / Battery': {
    'sensor.powerwall_solar_export':{color:'yellow'},
    'sensor.powerwall_battery_export':{color:'green'},
    'sensor.powerwall_battery_import':{color:'red'},
  },

  // 'Batt Charge': ['sensor.powerwall_battery_import'],
  // 'Fridge': ['sensor.kitchen_fridge_total_energy'],
  // 'HVAC': ['sensor.upstairs_hvac_total_energy','sensor.downstairs_hvac_blower_total_energy','sensor.downstairs_hvac_compressor_total_energy'],

  // 'Upstairs HVAC': {'sensor.upstairs_hvac_total_energy':{color:'yellow'}},
  // 'Downstairs HVAC': {'sensor.downstairs_hvac_blower_total_energy':{color:'pink'},'sensor.downstairs_hvac_compressor_total_energy':{color:'pink'}},

  'HVAC': {
    'sensor.upstairs_hvac_total_energy':{color:'yellow'},
    // 'sensor.downstairs_hvac_blower_total_energy':{color:'pink'},'sensor.downstairs_hvac_compressor_total_energy':{color:'pink'},
    'sensor.downstairs_hvac_total_energy':{color:'#c7388f'},
  },

  'Water Heater / Fridge / etc': {
    'sensor.heat_pump_water_heater_total_energy':{color:'#c7388f'},
    'sensor.kitchen_fridge_total_energy':{color:'silver'},
    // 'sensor.kitchen_plugs_total_energy':{color:'teal'},
    'sensor.dishwasher_total_energy':{color:'pink'},
    'sensor.garage_fridge_and_freezer_and_outlets_total_energy':{color:'orange'},
    'sensor.instant_hot_water_heater_total_energy':{color:'red'},
  },
   // 'EVs_': ['sensor.mom_y_charger_total_energy','sensor.bakah_charger_total_energy'],
  'EVs': {'sensor.mom_y_energy_added':{color:'pink'},'sensor.bakah_energy_added':{color:'teal'}},
};


const powerMeters = [
  
];


const energyMeters = [
  // 'sensor.downstairs_hvac_blower_total_energy',
  // 'sensor.downstairs_hvac_compressor_total_energy',
  'sensor.downstairs_hvac_total_energy',
  'sensor.garage_fridge_and_freezer_and_outlets_total_energy',
  'sensor.heat_pump_water_heater_total_energy',
  'sensor.kitchen_fridge_total_energy',
  // 'sensor.kitchen_plugs_total_energy',
  'sensor.dishwasher_total_energy',
  'sensor.left_laundry_total_energy',
  'sensor.right_laundry_total_energy',
  'sensor.upstairs_hvac_total_energy',
  'sensor.networking_and_compute_total_energy',
  'sensor.instant_hot_water_heater_total_energy',
  'sensor.powerwall_battery_import',
  'sensor.powerwall_load_import',
  'sensor.powerwall_site_import',
  'sensor.powerwall_battery_export',
  'sensor.powerwall_site_export',
  'sensor.powerwall_solar_export',
];

const energySinksAndSources = {
  'sources': {
    'Grid': ['sensor.powerwall_site_import'],
    'Solar': ['sensor.powerwall_solar_export'],
    'Battery Discharge': ['sensor.powerwall_battery_export'],
  },
  'overall_sink': {
    'Load': ['sensor.powerwall_load_import'],
  },
  'sinks': {
    'Battery Charge': [
      'sensor.powerwall_battery_import',
    ],
    'Kitchen': [
      'sensor.dishwasher_total_energy',
      'sensor.kitchen_plugs_1_total_energy',
      'sensor.kitchen_plugs_2_total_energy',
    ],
    'Refrigeration': [
      'sensor.kitchen_fridge_total_energy',
      'sensor.garage_fridge_and_freezer_and_outlets_total_energy',
    ],
    'Networking': [
      'sensor.networking_a_total_energy',
      'sensor.networking_b_total_energy',
    ],
    'HVAC': [
      'sensor.upstairs_hvac_total_energy',
      'sensor.downstairs_hvac_total_energy',
    ],
    'Laundry': [
      'sensor.left_laundry_total_energy',
      'sensor.right_laundry_total_energy',
    ],
    'EV Charging': [
      'sensor.mom_y_energy_added',
      'sensor.bakah_energy_added',
    ],
    'Water Heating': [
      'sensor.heat_pump_water_heater_total_energy',
      'sensor.instant_hot_water_heater_total_energy',
    ],
  },
};
  

const powerSinksAndSources = {
  'sources': {
    'Grid': ['sensor.powerwall_site_now'],
    'Solar': ['sensor.powerwall_solar_now'],
    'Battery': ['sensor.powerwall_battery_now'],
  },
  'overall_sink': {
    'Load': ['sensor.powerwall_load_now'],
  },
  'sinks': {
    'Battery': [
      'sensor.powerwall_battery_now',
    ],
    'Kitchen': [
      'sensor.kc868_m16_panel_power_dishwasher',
      'sensor.kc868_m16_panel_power_kitchen_plugs_1',
      'sensor.kc868_m16_panel_power_kitchen_plugs_2',
    ],
    'Refrigeration': [
      'sensor.kc868_m16_panel_power_kitchen_fridge',
      'sensor.kc868_m16_panel_power_garage_outlets_and_fridges',
    ],
    'Networking': [
      'sensor.kc868_m16_subpanel_power_networking_a',
      'sensor.kc868_m16_subpanel_power_networking_b',
    ],
    'HVAC': [
      'sensor.kc868_m16_panel_power_upstairs_hvac',
      'sensor.kc868_m16_panel_power_downstairs_hvac_compressor',
      'sensor.kc868_m16_subpanel_power_downstairs_hvac_blower',
    ],
    'Laundry': [
      'sensor.kc868_m16_panel_power_left_laundry',
      'sensor.kc868_m16_panel_power_right_laundry',
    ],
    'EV Charging': [
      'sensor.mom_y_charger_power',
      'sensor.bakah_charger_power',
    ],
    'Water Heating': [
      'sensor.kc868_m16_subpanel_power_water_heater_and_circulator',
      'sensor.kc868_m16_panel_power_instant_hot_water_heater',    
    ],
  },
};


const hvacPowerSinks = {
  'climate.parents_room_2': {
    power: [
      'sensor.kc868_m16_panel_power_upstairs_hvac',
      ],
    energy: [
      'sensor.upstairs_hvac_total_energy',
      ],
  },
  'climate.kitchen_2': {
    power: [
      'sensor.kc868_m16_panel_power_downstairs_hvac_compressor',
      'sensor.kc868_m16_subpanel_power_downstairs_hvac_blower',
    ],
    energy: [
      'sensor.downstairs_hvac_total_energy',
    ],
  },
};

const primaryCameras = [
    'camera.front_door_high',
    'camera.driveway_high',
    // 'camera.lower_driveway_high',
];

const interestingTemperatures = {
  'Bedrooms': {
    'sensor.alex_room_temperature':{strokeWidth:2,color:'teal'},
    'sensor.kate_room_temperature':{strokeWidth:2,color:'purple'},
    'sensor.zoe_room_temperature':{strokeWidth:2,color:'pink'},
    'sensor.gw1000b_v1_7_6_temperature_7':{strokeWidth:2,color:'white'},
  },
  'Kitchen / Office / Laundry': {
    'sensor.gw1000b_v1_7_6_temperature_4':{color:'white'},
    'sensor.gw1000b_v1_7_6_temperature_8':{strokeWidth:2,color:'blue'},
    'sensor.gw1000b_v1_7_6_indoor_temperature':{color:'green'},
  },
  // 'Alex\'s Bedroom': {
  //   'sensor.alex_room_temperature':{color:'teal'},
  // },
  // 'Kate\'s Bedoom': {
  //   'sensor.kate_room_temperature':{color:'purple'},
  // },
  // 'Zoë\' Bedroom': {
  //   'sensor.zoe_room_temperature':{color:'pink'},
  // },
  // 'Parents\' Bedroom': {
  //   'sensor.gw1000b_v1_7_6_temperature_7':{color:'white'},
  // },
  // 'Office': {
  //   'sensor.gw1000b_v1_7_6_temperature_8':{},
  // },

  'Crawl / Attic / Bsmt / Garage / Ext': {
    'sensor.gw1000b_v1_7_6_temperature_2':{strokeWidth:2,color:'green'},
    'sensor.gw1000b_v1_7_6_temperature_6':{strokeWidth:2,color:'orange'},
    'sensor.gw1000b_v1_7_6_temperature_1':{strokeWidth:2,color:'yellow'},
    'sensor.gw1000b_v1_7_6_temperature_3':{strokeWidth:2,color:'gray'},
    'sensor.gw1000b_v1_7_6_outdoor_temperature':{strokeWidth:6,color:'white'},
  },
  'Water Heater': {
    'sensor.esp_poe1_lower_tank_temperature':{strokeWidth:2,color:'blue'},
    'sensor.esp_poe1_upper_tank_temperature':{strokeWidth:2,color:'#c7388f'},
  },
  // 'HVAC_state_maybe': ['climate.kitchen_2'],
};

const interestingWaterQuantities = {
  // 'Hot Water': {
    // 'sensor.esphome_web_a8c7c4_total_dispensed_hot_water':{color:'#c7388f'},
    // 'sensor.esphome_web_a8c7c4_total_circulated_hot_water':{color:'white'},
  // },
  // 'All Water': {'sensor.water_meter_municipal_water_volume':{color:'blue'}},
  'Water': {
    'sensor.esphome_web_a8c7c4_total_dispensed_hot_water':{color:'#c7388f'},
    'sensor.water_meter_municipal_water_volume':{color:'blue'},
  },
};


export {
  exteriorAreaIds,
  bedroomAreaIds,
  ignoreMotionIds,
  energyConsumers,
  energyMeters,
  interestingTemperatures,
  interestingWaterQuantities,
  primaryCameras,
  powerSinksAndSources,
  energySinksAndSources,
  hvacPowerSinks,
};