import './App.css';
import './react-tabs.css';
import {
  useAreas,
  useEnts,
  useFind,
} from './hooks/useHomeAssistantCore.js';

import {
  useBinarySensorsWithDeviceClass,
  useSensorsWithDeviceClass,
} from './hooks/useHomeAssistant.js';

import {
  AllDoors,
  AllGarageDoors,
  AllLights,
  AllMotions,
  AllWindows,
  LightsCluster,
  Area,
  Entity,
  LEDBar,
} from './Entities.jsx';

import {
  EnergyFlow,
} from './EnergyFlow.jsx';

import { useEffect, useState, React } from 'react';
import { ThemeProvider, SectionLayout } from './homekit-react-components/src/';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import spinner from './images/spinner-animated.svg';

import {
  exteriorAreaIds,
  bedroomAreaIds,
  ignoreMotionIds,
} from './houseConfig';


// import { Sparklines, SparklinesLine, SparklinesCurve, SparklinesBars } from 'react-sparklines';
// import { useVisible } from 'react-hooks-visible';
// import stb from './timeBuckets.js';


// function mapSwitch(v) {
//   if (v === 'on') {
//     return 1;
//   }
//   return 0;
// }


// function LastChanged({ent,history}) {
// 
//   const [date, setDate] = useState(new Date());
//   const [show, setShow] = useState(false);
//   const [delta,value,units] = getAgo(ent ? (new Date(ent.last_changed)).getTime() : Date.now());
// 
//   const tick = () => {
//     setDate(new Date());
//   };
// 
//   useEffect(() => {
//     const interval = setInterval(() => {
//       tick();
//     }, delta < 60000 ? 1000 : 60000);
//     return () => {
//       clearInterval(interval);
//     };
//   }, [delta]);
// 
// 
//   return (
//     <div>
//     {show?<div style={{backgroundColor:'white',border:'10px solid white',position:'absolute','left':'30vw','top':'20vh',zIndex:5,width:'40vw',height:300}}>
//       <span style={{cursor:'pointer'}} onClick={() => setShow(false)}>X</span>
//       <PolledHistoryGraph history={history} map={mapSwitch} useMax={true} />
//       <div style={{height:250,width: '90%',overflowY:'scroll'}}>
//         <table>
//           <thead>
//             <tr><td>State</td><td>Changed</td><td>Time</td></tr></thead>
//           <tbody>
//             {history.sort((a,b) => a.last_changed>b.last_changed?-1:1).map(r => {
//               const at = new Date(r.last_changed);
//               const [delta,value,units] = getAgo(at.getTime());
//               return (
//                 <tr key={r.last_changed}>
//                   <td>{r.state}</td>
//                   <td>{value}{units} ago</td>
//                   <td>{at.toLocaleTimeString()}</td>
//                 </tr>
//               );
//             })}
//           </tbody>
//         </table>
//       </div>
//     </div>:null}
//     <span style={{cursor:'pointer'}} onClick={() => setShow(true)} className='lastChanged'>{value}{units}</span>
//     </div>
//   );
// }



// function PolledHistoryGraph({history,map=(f)=>parseFloat(f),useMax=false}) {
//   const series = history.map(r => [
//     (new Date(r.last_changed)).getTime(),
//     map(r.state),
//   ]).filter(r => !isNaN(r[1]));
// 
//   const buckets = stb(series,useMax,100);
// 
//   // console.log('buckets',buckets);
//   // const t = new tsa.main(series);
//   // const out = t.dsp_itrend({
//   //  alpha:   0.7
//   // });
//   // console.log('out',out);
//   // return null;
//   /*
//   const now = Date.now();
//   const series = history
//     .map(r => [
//       map(r.state),
//       (now - (new Date(r.last_changed)).getTime())/1000,
//       ]
//     )
//     .filter(r => !isNaN(r[0]));
// 
//   if (series.length < 2) {
//     return null;
//   }
// 
//   const smoother = Smooth(series, Smooth.METHOD_NEAREST);
//   const smoothed = [];
//   const newLen = 100;
//   for (let i=0;i<newLen;i++) {
//     const [v,t] = smoother(series.length * i/newLen);
//     smoothed.push(v);
//   }
//   */
//   const color = '#9bbdc0';
// 
//   const curve = <SparklinesCurve color={color} style={{ fill: 'none', strokeWidth:2 }} />;
//   const bar = <SparklinesBars style={{strokex:'rgba(0,0,0,0)',stroke:color,fill:color}} />;
// 
//   const min = 0;
//   const max = 1;
// 
//   return (
//     <div className='history'>
//       <Sparklines data={buckets} style={{borderRadius:1,boxShadow:'2px 2px 3px inset black',border:'none',backgroundColor:'grey'}} svgHeight={8} svgWidth={100} width={100} height={12} margin={0} min={min} max={max} >
//       {useMax?bar:curve}
//       </Sparklines>
//     </div>
//   );
// }

function LightsClustersByArea() {
  const light_ids = useFind(/^light\./);
  const areas = useAreas();
  const grouped = {};
  areas.ordered.map(area_id => {
    if (area_id !== null && area_id !== 'null') {
      if (areas.area_to_id[area_id] === undefined) {
        return;
      }
      grouped[area_id] = light_ids
        .filter(a => areas.area_to_id[area_id].includes(a));
    }
  });
  return Object.keys(grouped).map(g =>
    <LightsCluster key={g} name={areas.areas[g].name} entity_ids={grouped[g]} />,
  );
}


function EntitiesGroupdByArea({entity_ids}) {
  const areas = useAreas();
  const grouped = {};
  for (let area_id in areas.area_to_id) {
    grouped[area_id] = entity_ids
      .filter(a => areas.area_to_id[area_id].includes(a));
  }
  return (
    Object.keys(grouped).map(g => <Area key={g} area_id={g} entity_ids={grouped[g]} />)
  );
}

function App() {
  const [tabIndex, setTabIndex] = useState(0);
  const [secondsLeft, setSecondsLeft] = useState(0);

  let ids = useFind(/(^sensor\.|^light\..*|^binary_sensor\.)/i);
  const areas = useAreas();

  const light_ids = useFind(/^light\./);
  const temperature_ids = useSensorsWithDeviceClass('temperature');
  const humidity_ids = useSensorsWithDeviceClass('humidity');
  const door_ids = useBinarySensorsWithDeviceClass('door');
  const window_ids = useBinarySensorsWithDeviceClass('window');
  const camera_ids = useFind(/^camera\./i);
  const scene_ids = useFind(/^scene\./i);
  const energy_ids = useFind(/(powerwall|energy)/);

  useEffect(() => {
    const iv = setInterval(() => {
      if (tabIndex !== 0) {
        if (secondsLeft) {
          setSecondsLeft(secondsLeft - 1);
        } else {
          setTabIndex(0);
        }
      }
    },1000);
    return () => {
      clearInterval(iv);
    };
  }, [secondsLeft,tabIndex]);


  const exterior_camera_ids = useEnts(camera_ids)
    .filter(e => e !== null && exteriorAreaIds.includes(e.area_id))
    .map(e => e.entity_id);
  const interior_camera_ids = useEnts(camera_ids)
    .filter(e => e !== null && !exteriorAreaIds.includes(e.area_id))
    .map(e => e.entity_id);

  const public_scene_ids = useEnts(scene_ids)
    .filter(e => e !== null && !bedroomAreaIds.includes(e.area_id))
    .map(e => e.entity_id);

  const car_ids = areas && areas.area_to_id && areas.area_to_id['cars'] ? areas.area_to_id['cars'] : [];

  const exclude_ids = [
    ...camera_ids,
    ...temperature_ids,
    ...humidity_ids,
    ...door_ids,
    ...window_ids,
    ...scene_ids,
    ...light_ids,
    ...car_ids,
    ...energy_ids,
  ];

  ids = ids.filter(r => !exclude_ids.includes(r));

  return (
    <ThemeProvider>
      <Tabs selectedIndex={tabIndex} onSelect={(index) => {setSecondsLeft(180);setTabIndex(index);}}>
        <TabList>
          <Tab>Main</Tab>
          <Tab>Lights</Tab>
          <Tab>Cameras</Tab>
          <Tab>Doors & Windows</Tab>
          <Tab>Temperatures</Tab>
          <Tab>Cars</Tab>
          <Tab>Energy</Tab>
          <Tab>Everything Else</Tab>
          <Tab disabled>{'.'.repeat(Math.ceil(secondsLeft/5))}</Tab>
        </TabList>
{!ids.length?(
        <TabPanel>
          <div className='App'>
            <SectionLayout title={''}>
              <img style={{position:'fixed',width:'20vw',height:'20vw',left:'40vw',top:'35vh'}} src={spinner} />
            </SectionLayout>
          </div>
        </TabPanel>
):null}
        <TabPanel>
          <div className='App'>
            <SectionLayout title={''}>
              <AllLights interior={false} />
              <AllLights interior={true} />
              <AllGarageDoors /> 
              <AllDoors interior={false} />
              <AllWindows />
              <AllMotions exclude={ignoreMotionIds} />
              <AllDoors interior={true} />
              <Entity entity_id={'climate.parents_room_2'} />
              <Entity entity_id={'climate.kitchen_2'} />
              <Entity entity_id={'sensor.powerwall_site_now'} />
              <Entity entity_id={'sensor.powerwall_site_import'} />
              <Entity entity_id={'binary_sensor.esp_poe1_compressor'} />
              {/* <Entity entity_id={'sensor.water_heater_energy_tracking'} /> */}

              <Entity entity_id={'binary_sensor.bakah_charger'} />
              <Entity entity_id={'binary_sensor.mom_y_charger'} />
            </SectionLayout>
            <SectionLayout title={'Room Lights'}>
              <LightsClustersByArea />
            </SectionLayout>
            <Area label={'Scenes'} entity_ids={public_scene_ids} stripNames={false} />
            <LEDBar />
          </div>
        </TabPanel>
        <TabPanel>
          <div className='App'>
            <SectionLayout title={'Rooms'}>
              <LightsClustersByArea />
            </SectionLayout>
            <EntitiesGroupdByArea entity_ids={light_ids} />
          </div>
        </TabPanel>
        <TabPanel>
          <div className='App'>
            <Area label={'Exterior'} entity_ids={exterior_camera_ids} />
            <Area label={'Interior'} entity_ids={interior_camera_ids} />
          </div>
        </TabPanel>
        <TabPanel>
          <div className='App'>
            <EntitiesGroupdByArea entity_ids={[...door_ids,...window_ids]} />
          </div>
        </TabPanel>
        <TabPanel>
          <div className='App'>
            <EntitiesGroupdByArea entity_ids={[...temperature_ids,...humidity_ids]} />
          </div>
        </TabPanel>
        <TabPanel>
          <div className='App'>
            <Area label={'Meeeeeow'} entity_ids={car_ids.filter(i=>i.match(/meeeeeow/))} />
            <Area label={'Mom-Y'} entity_ids={car_ids.filter(i=>i.match(/mom_y/))} />
          </div>
        </TabPanel>
        <TabPanel>
          <div className='App'>
            <EnergyFlow />
            <Area label={'Energy Metrics'} entity_ids={energy_ids} />
          </div>
        </TabPanel>
        <TabPanel>
          <div className='App'>
            <EntitiesGroupdByArea entity_ids={ids} />
          </div>
        </TabPanel>
{ids.length?(
        <TabPanel>
        </TabPanel>
):null}
      </Tabs>
    </ThemeProvider>
  );
}

export default App;
