import {
  // useAreas,
  useEnts,
  // useHistory,
  useFind,
} from './hooks/useHomeAssistantCore.js';
import './Tester.scss';

import { ResponsiveSankey } from '@nivo/sankey';

import { 
  // useEffect,
  // useState,
  React,
   } from 'react';

import {
  // exteriorAreaIds,
  // bedroomAreaIds,
  // ignoreMotionIds,
  // energyConsumers,
  // interestingTemperatures,
  // primaryCameras,
  // interestingWaterQuantities,
} from './houseConfig';


import powerData from './power.json';

import { Chart } from 'react-google-charts';

function clean(a) {
  if (kAliases[a]) {
    return kAliases[a];
  }
  return a
    .replace('sensor.','')
    .replace('_subpanel','')
    .replace('_panel','')
    .replace('_power','')
    .replace('kc868_m16_','')
    .replace('_poe_power','')
    .replace('_switch_','_')
    .replace('powerwall_','');
    // .replace('subpanel','sub_');
    // .replace('phase_','')
    // .replace('power_','')
    // .replace('panel_','');
}

const powerOptions = {
  height: 775,
  width: 1350,
  sankey: {
    iterations: 500, //default 32
    // link: {
    //   color: { fill: 'purple' },
    // },
    animation:{
      duration: 1000,
      easing: 'in',
    },

    node: {
      // colors: ['pink'],
      nodePadding: 3,
      width: 25,
      label: {
        fontName: 'SF UI Display',
        fontSize: 8,
        color: '#fff',
        bold: true,
        italic: false,
      },
    },
  },
};

const kLoad = 'sensor.powerwall_load_now';
const kBatt = 'sensor.powerwall_battery_now';
const kSolar = 'sensor.powerwall_solar_now';

const kAliases = {
  'sensor.powerwall_site_now': 'Grid',
  'sensor.powerwall_solar_now': 'Solar',
  'sensor.powerwall_battery_now': 'Powerwall Battery',
  'sensor.powerwall_load_now': 'House Load',
};


const kDependsOnSign = [
  'sensor.powerwall_battery_now',
  'sensor.powerwall_site_now',
];


const parents = {

  // 'sensor.powerwall_site_now': [
  // ],

  'sensor.powerwall_load_now': [
    'sensor.kc868_m16_panel_power_phase_a',
    'sensor.kc868_m16_panel_power_phase_b',
  ],

  'sensor.kc868_m16_subpanel_power_networking_a': [
    'sensor.networking_ups_load_approx_power',
  ],
  'sensor.kc868_m16_subpanel_power_networking_b': [
    'sensor.server_ups_load_approx_power',
  ],

  // arbitrary
  'sensor.networking_ups_load_approx_power': [
    'sensor.main_switch_port_1_poe_power',
    'sensor.main_switch_port_2_poe_power',
    'sensor.main_switch_port_3_poe_power',
    'sensor.main_switch_port_4_poe_power',
    'sensor.main_switch_port_5_poe_power',
    'sensor.main_switch_port_6_poe_power',
    'sensor.main_switch_port_7_poe_power',
    'sensor.main_switch_port_8_poe_power',
    'sensor.main_switch_port_9_poe_power',
    'sensor.main_switch_port_10_poe_power',
    'sensor.main_switch_port_11_poe_power',
    'sensor.main_switch_port_12_poe_power',
    'sensor.main_switch_port_13_poe_power',
    'sensor.main_switch_port_14_poe_power',
    'sensor.main_switch_port_15_poe_power',
    'sensor.main_switch_port_16_poe_power',
    'sensor.main_switch_port_17_poe_power',
    'sensor.main_switch_port_18_poe_power',
    'sensor.main_switch_port_19_poe_power',
    'sensor.main_switch_port_20_poe_power',
    'sensor.main_switch_port_21_poe_power',
    'sensor.main_switch_port_22_poe_power',
    'sensor.main_switch_port_23_poe_power',
    'sensor.main_switch_port_24_poe_power',
  ],

  // arbitrary
  'sensor.server_ups_load_approx_power': [
    'sensor.crawlspace_switch_port_1_poe_power',
    'sensor.crawlspace_switch_port_2_poe_power',
    'sensor.crawlspace_switch_port_3_poe_power',
    'sensor.crawlspace_switch_port_4_poe_power',
    'sensor.crawlspace_switch_port_5_poe_power',
    'sensor.crawlspace_switch_port_6_poe_power',
    'sensor.crawlspace_switch_port_7_poe_power',
    'sensor.crawlspace_switch_port_8_poe_power',
    'sensor.crawlspace_switch_port_9_poe_power',
    'sensor.crawlspace_switch_port_10_poe_power',
    'sensor.crawlspace_switch_port_11_poe_power',
    'sensor.crawlspace_switch_port_12_poe_power',
    'sensor.crawlspace_switch_port_13_poe_power',
    'sensor.crawlspace_switch_port_14_poe_power',
    'sensor.crawlspace_switch_port_15_poe_power',
    'sensor.crawlspace_switch_port_16_poe_power',
    'sensor.crawlspace_switch_port_17_poe_power',
    'sensor.crawlspace_switch_port_18_poe_power',
    'sensor.crawlspace_switch_port_19_poe_power',
    'sensor.crawlspace_switch_port_20_rds_poe_power',
    'sensor.crawlspace_switch_port_21_poe_power',
    'sensor.crawlspace_switch_port_22_poe_power',
    'sensor.crawlspace_switch_port_23_poe_power',
    'sensor.crawlspace_switch_port_24_poe_power',    
  ],

  'sensor.crawlspace_switch_port_23_poe_power': [
    'sensor.attic_mini_switch_port_2_poe_power',
    'sensor.attic_mini_switch_port_3_poe_power',
    'sensor.attic_mini_switch_port_4_poe_power',
    'sensor.attic_mini_switch_port_5_poe_power',
  ],
  'sensor.crawlspace_switch_port_16_poe_power': [
    'sensor.garage_mpoe_switch_port_2_poe_power',
    'sensor.garage_mpoe_switch_port_3_poe_power',
    'sensor.garage_mpoe_switch_port_4_poe_power',
    'sensor.garage_mpoe_switch_port_5_poe_power',
  ],

  'sensor.kc868_m16_panel_power_phase_a': [
    'sensor.kc868_m16_subpanel_power_subpanel_a',
    // both
    'sensor.kc868_m16_panel_power_upstairs_hvac',
    'sensor.kc868_m16_panel_power_downstairs_hvac_compressor',

    // high-confidence
    'sensor.kc868_m16_panel_power_right_laundry',
    'sensor.kc868_m16_panel_power_kitchen_plugs_1',

    // made-up

  ],
  'sensor.kc868_m16_panel_power_phase_b': [
    'sensor.kc868_m16_subpanel_power_subpanel_b',
    // both
    'sensor.kc868_m16_panel_power_upstairs_hvac',
    'sensor.kc868_m16_panel_power_downstairs_hvac_compressor',

    // high-confidence
    'sensor.kc868_m16_panel_power_dishwasher',
    'sensor.kc868_m16_panel_power_left_laundry',

    // low-confidence
    'sensor.kc868_m16_panel_power_kitchen_fridge',

    // made-up
    'sensor.kc868_m16_panel_power_garage_outlets_and_fridges',
    'sensor.kc868_m16_panel_power_office_plugs',
    'sensor.kc868_m16_panel_power_instant_hot_water_heater',
    'sensor.kc868_m16_panel_power_kitchen_plugs_2',
    'sensor.kc868_m16_panel_power_upstairs_bathroom',
  ],

  'sensor.kc868_m16_subpanel_power_subpanel_a': [
    'sensor.kc868_m16_subpanel_power_networking_a',
    // both
    'sensor.kc868_m16_subpanel_power_downstairs_hvac_blower',
    'sensor.kc868_m16_subpanel_power_oven',

    // high confidence
    'sensor.kc868_m16_subpanel_power_water_heater_and_circulator',


    // made-up
    'sensor.kc868_m16_subpanel_power_subpanel_3',
    'sensor.kc868_m16_subpanel_power_basement_outlets',
    'sensor.kc868_m16_subpanel_power_subpanel_6',
  ],
  'sensor.kc868_m16_subpanel_power_subpanel_b': [
    'sensor.kc868_m16_subpanel_power_networking_b',
    // both
    'sensor.kc868_m16_subpanel_power_downstairs_hvac_blower',
    'sensor.kc868_m16_subpanel_power_oven',

    // hight confidence
    'sensor.kc868_m16_subpanel_power_basement_lights_and_outlets',
    'sensor.kc868_m16_subpanel_power_back_yard_motion_light',


    // made-up
    'sensor.kc868_m16_subpanel_power_downstairs_bedroom_outlets_1',
    'sensor.kc868_m16_subpanel_power_downstairs_bedroom_outlets_2',
  ],

};


// laundry reading way too high.
// around 2k but should be closer to 1k
// feb 11 12:30 pm -- nonlinear somewhere? where's the error coming from?
// only applicable to high values? something reactive power something?


// import {
  // EnergyHistory,
  // TemperatureHistory,
  // WaterHistory,
 // } from './HistoryGraphs';



function Tester() {
  const entity_ids = useFind(/^sensor\..*power/);

  const ups_ids = ['sensor.server_ups_load','sensor.server_ups_nominal_real_power','sensor.networking_ups_load', 'sensor.networking_ups_nominal_real_power'];
  const ups = useEnts(ups_ids);
  const entities = useEnts(entity_ids);

  const ats = {};
  for(let i=0;i<ups_ids.length;i+=2) {
    if (ups.length === ups_ids.length) {
      entities.push({
        entity_id: ups_ids[i] + '_approx_power',
        state: 0.01 * parseFloat(ups[i].state) * parseFloat(ups[i+1].state),
        attributes: { friendly_name: ups[i].attributes.friendly_name + ' Approx Power'},
      });
    }
  }


  const stateMap = {};
  entities.map(u => {
    let st = parseFloat(u.state);
    if (isNaN(st)) {
      return;
    }
    let m = 1.0;
    switch(u?.attributes?.unit_of_measurement) {
      case 'W':
        m = 1.0;
        break;
      case 'kW':
        m = 1000.0;
        break;
    }
    stateMap[u.entity_id] = st * m;
  });
  // console.log(stateMap);


  const entMap = {};
  entities.map(e => entMap[e.entity_id] = e);

  const data = [['To','From','Weight']];
  const accounted = {};

  for (let k in parents) {
    parents[k].map(x => {
      if (entMap[x] && !x.match(/poe_/)) {
        if (!accounted[k]) {
          accounted[k] = 0;
        }
        accounted[k] += stateMap[x]||0;
        if (!ats[x]) {
          ats[x] = [];
        }
        ats[x].push(data.length);
        data.push([k, x, stateMap[x]||0]);
      }
    });
  }
// console.log({ats});
  for (let k in ats) {
    // account for split phase entities (oven, hvac)
    ats[k]
      // .filter(i => data[i][1] !== 'sensor.powerwall_load_now')
      .map(i => data[i][2] /= ats[k].length);
  }


  const kGateway = 'Tesla Gateway';
  kDependsOnSign.map(p => {
    if (stateMap[p] > 0) {
      data.push([p, kGateway, Math.abs(stateMap[p])]);
    } else {
      data.push([kGateway, p, Math.abs(stateMap[p])]);
    }
  });
  data.push([kGateway, kLoad, stateMap[kLoad]]);
  data.push([kSolar, kGateway, kLoad, stateMap[kSolar]]);


  const slops = {};
  for (let k in accounted) {
    const measured = stateMap[k] || 0;
    const unaccounted = measured - accounted[k];
    slops[k] = unaccounted;
    if (unaccounted < 0) {
      // data.push([k,'OVERCOUNTED '+k,-unaccounted]);
    } else {
      // data.push([k,'UNACCOUNTED UNDER '+k,unaccounted]);
    }
  }

  const light_ids = useFind(/light\./);
  const on_lights = useEnts(light_ids).filter(e => e.state === 'on');
  const pp = {
    'a': 'sensor.kc868_m16_panel_power_phase_a',
    'b': 'sensor.kc868_m16_panel_power_phase_b',
    'sub_a': 'kc868_m16_subpanel_power_subpanel_a',
    'sub_b': 'kc868_m16_subpanel_power_subpanel_b',
  };

  on_lights.map(e => {
    if (powerData[e.entity_id]?.parent) {
      data.push([
        powerData[e.entity_id]?.parent,
        e.entity_id,
        powerData[e.entity_id]?.value || 0,
      ]);
    } else {
      const lb = [
        pp[(powerData[e.entity_id]?.sub?'sub_':'') + powerData[e.entity_id]?.phase],
        e.entity_id,
        powerData[e.entity_id]?.value || 0,
      ];
      // console.log(lb);
      data.push(lb);
    }
  });
  // const light_powers_a = on_lights.filter(e => powerData[e.entity_id].phase == 'a').map(e => powerData[e.entity_id]?.value || 0);
  // const light_powers_b = on_lights.filter(e => powerData[e.entity_id].phase == 'b').map(e => powerData[e.entity_id]?.value || 0);


  // pretty good but just guessing.
  // data.push(['sensor.networking_ups_load_approx_power','Unifi UDM Pro',20]);
  // data.push(['sensor.server_ups_load_approx_power','Supermicro 1U',25]);
  // data.push(['sensor.networking_ups_load_approx_power','Unifi 24 POE Pro',15]);
  // data.push(['sensor.server_ups_load_approx_power','Unifi 24 POE',15]);



  const filteredData = data.filter((e,i) => i === 0 || e[2] > .1).map(a => [clean(a[0]),clean(a[1]),a[2]]);
  // const filteredData = data.slice();
  // console.log(filteredData);
  if (!window) {
    let node_ids = {};
    const links = [];
    const nodes = [];
    const rsdata = {nodes:[],links:[]};
    filteredData.filter((r,i) => i>0).map((r,i) => {rsdata.links.push({source:r[0],target:r[1],value:r[2]});node_ids[r[0]]=true;node_ids[r[1]]=true;});
    Object.keys(node_ids).map((n,i) => rsdata.nodes.push({id:n}));
    rsdata.nodes = rsdata.nodes.sort((a,b) => stateMap[a] > stateMap[b] ? -1 : 1);
    // console.log(links);
    // console.log(nodes);
    if (rsdata.nodes.length === 0 || rsdata.links.length === 0) {
      return null;
    }
    return (
      <div style={{color:'white'}}>
        <div className='app' style={{margin:0,width:powerOptions.width,height:powerOptions.height}}>
          <ResponsiveSankey
              margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
              layout={'horizontal'}
              sort="input"
              linkOpacity={0.4}
              linkBlendMode={'normal'}
              data={rsdata}
              labelPadding={2}
              isInteractive={true}
              // colors={['purple','pink','purple','pink']}
              colors={{ scheme: 'category10' }}
              labelTextColor={'white'}
              enableLinkGradient={true}

              />
        </div>
      </div>
    );
  }
  return (
    <div style={{color:'white'}}>
      <pre style={{display:'none'}}>{JSON.stringify(slops,null,2)}</pre>
      <div className='app' style={{margin:0,width:powerOptions.width,height:powerOptions.height}}>
        <Chart
          chartType='Sankey'
          data={filteredData}
          options={powerOptions}
        />
      </div>
      <table>
      <tbody>
      {entities.filter(e => Math.abs(parseFloat(e.state))>0).map(e => <tr key={e.entity_id}><td>{e.entity_id}</td><td>{e.attributes.friendly_name}</td><td>{e.state}</td></tr>)}
      </tbody>
      </table>
    </div>
  );
}

export default Tester;