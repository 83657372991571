// import { useHA_URL } from '../useMQTT';
import { React, useState, useEffect } from 'react';
import date from 'date-and-time';

import {
  // useTimeout,
  usePrevious,
  useInterval,
} from 'react-use';

import { useURL } from './hooks/useHomeAssistantCore.js';

import { Calendar as TimelineCalendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment-timezone';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import './Calendar.scss';

const localizer = momentLocalizer(moment);

import spinner from './images/spinner-animated.svg';
const spin = (
  <img alt='nah' style={{
    position:'fixed',
    width:'10vw',
    height:'10vw',
    left:'30vw',
    top:'20vw',
  }} src={spinner} />
);

function useIntervalCounter(interval) {
  const [counter, setCounter] = useState(0);
  useEffect(() => {
    const iv = setInterval(() => {
      console.log('timer fired reloading');
      setCounter(counter + 1);
    }, 1000 * interval);

    return () => {
      clearInterval(iv);
    };
  },[interval, counter]);
  return counter;
}

function useCalendar(calendar, interval=300000) {
  // TODO - use usePrevious to show something useful 
  // even while re-loading the calendar subsequent times?

  const [count, setCount] = useState(0);
  const s = new Date(Date.now() - 1 * 86400000);
  const e = new Date(s.getTime() + 7 * 86400000);
  let param = '?start=' + date.format(s,'YYYY-MM-DD') + '&end=' + date.format(e,'YYYY-MM-DD');
  const { loading, error, data, get } = useURL('calendars/calendar.' + calendar + param);
  const previous = usePrevious(data);
  useInterval(get, interval);

  if (loading && previous) {
    return { loading: false, error: null, data: previous };
  }
  return { loading, error, data };
}


function Calendar({days}) {
  const interval = 600 * 1000;
  const c1 = useCalendar('family', interval);
  const c2 = useCalendar('kids', interval);

  const cm = [];
  if (c1.loading || c2.loading) {
    return spin;
  } else if (c1.error || c2.error) {
    return (
      <div>Error? <pre>{JSON.stringify({c1,c2})}</pre></div>
    );
  } else if (!c1.data || !c2.data) {
    return (
      <div>Calendar error <pre>{JSON.stringify({c1,c2})}</pre></div>
    );
  } else if (!c1.data.filter || !c2.data.filter) {
    return (
      <div>Calendar data error</div>
    );
  }


  c1.data.filter(i => i.start && i.start.dateTime).map(i => {i.calendar='family';cm.push(Object.assign({},i));});
  c2.data.filter(i => i.start && i.start.dateTime).map(i => {i.calendar='kids';cm.push(Object.assign({},i));});

  // c1.data.filter(i => !(i.start && i.start.dateTime)).map(i => {i.calendar='family';cm.push(Object.assign({},i,{start:{date:i.start.date,dateTime:i.start.date + 'T08:00:00'},end:{date:i.start.date,dateTime:i.start.date + 'T20:00:00'}}));});
  // c2.data.filter(i => !(i.start && i.start.dateTime)).map(i => {i.calendar='kids';cm.push(Object.assign({},i,{start:{date:i.start.date,dateTime:i.start.date + 'T08:00:00'},end:{date:i.start.date,dateTime:i.start.date + 'T20:00:00'}}));});


  const cmd = cm.map(a => {
    a.start = new Date(a.start.dateTime);
    a.end = new Date(a.end.dateTime);
    a.title = a.summary;
    return a;
  });
  cmd.sort((a,b) => a.start.getTime() > b.start.getTime() ? 1 : -1);

  const dayResults = Array(days).fill(0).map((i,j)=>j).map(n => <CalendarDay n={n} key={'nplus'+n} events={cmd} />);
  const style = {
    display:'inline-block',
    verticalAlign:'top',
  };

  return (
    <div style={style}>{dayResults}</div>
  );
}

function CalendarDay({n,events}) {
  const thisday = (new Date(Date.now() + n*86400000)).getDate();
  const dayevents = events
    .filter(a => a.start.getDate() === thisday);
  const minHour = Math.min.apply(null, events.map(a => a.start.getHours()));

  const style = {
    display:'inline-block',
    width: 170,
  };
  return (
    <div style={style} className="" suppressHydrationWarning>
      <Timeline events={dayevents} width={style.width} minHour={minHour} />
    </div>
  );
}

// eslint-disable-next-line 
function eventStyleGetter(event, start, end, isSelected) {
  const style = {
    backgroundColor: 'blue',
    color: 'white',
    border: '0px',
    display: 'block',
    // fontSize: 9,
  };

  const aMatch = event.title.match(/Alex/) || event.title.match(/\bA\b/);
  const zMatch = event.title.match(/Zoe|Zoë/) || event.title.match(/\bZ\b/);
  const kMatch = event.title.match(/Kate/) || event.title.match(/\bK\b/);

  const aColor = '#20bde0';
  const zColor = '#e159b2';
  const kColor = '#a46cff';

  const grads = [];
  kMatch && grads.push(kColor);
  aMatch && grads.push(aColor);
  zMatch && grads.push(zColor);
  const clearColor = 'rgba(0,0,0,0)';
  const whiteColor = '#ccc';
  grads.length || grads.push(clearColor);
  const allGrads = [grads,whiteColor].flat();
  style.backgroundColor = 'grey';
  style.backgroundImage = 'linear-gradient(' + allGrads.join(',') + ')';

  return {
    style: Object.assign(style, event.style),
  };
}


function Timeline({events,width,minHour}) {
  const [counter, setCounter] = useState(0);
  const interval = 10;

  useEffect(() => {
    const iv = setInterval(() => {
      setCounter(counter + 1);
    }, 1000 * interval);

    return () => {
      clearInterval(iv);
    };
  },[interval, counter]);

  if (!events.length) {
    return null;
  }
  let min = new Date(events[0].start.getTime());
  min.setHours(minHour,0,0);
  let max = new Date(events[0].start.getTime());
  max.setHours(19,30,0);


  let defaultDate = new Date(events[0].start);
  const style = {
    width: width,
    // height: 500,
  };

  return (
    <div className="Timeline" style={style}>
      <div className="dayheader">{date.format(events[0].start,'ddd MMM D')}</div>
      <TimelineCalendar
        events={events}
        step={30 /*minutes*/}
        selectable={false}
        toolbar={false}
        hideGutter={true}
        defaultView="day"
        formats={{eventTimeRangeFormat:formatTime}}
        localizer={localizer}
        startAccessor="start"
        endAccessor="end"
        min={min}
        max={max}
        dayLayoutAlgorithm="overlap"
        eventPropGetter={eventStyleGetter}
        defaultDate={defaultDate}
      />
    </div>
  );
}

function formatTime(a) {
  let h = a.start.getHours() % 12;
  if (!h) {
    h = 12;
  }
  return h + ':' + a.start.getMinutes().toString().padStart(2,'0');
}
export default Calendar;
