import { useState, React } from 'react';
import {
  useHistoriesArray,
} from './hooks/useHomeAssistantCore.js';

import '../node_modules/react-vis/dist/style.css';
import {
  XYPlot,
  XAxis,
  YAxis,
  MarkSeries,
  PolygonSeries,
  VerticalBarSeries,
  // HorizontalGridLines,
  VerticalGridLines,
  LineSeries,
  GradientDefs,
} from 'react-vis';

import {
  // ASAP,
  LTD,
} from 'downsample';

// import { Smooth } from 'smooth.ts';

import { useInterval } from 'react-use';

import resolveRate from './utilityRates.js';

import spinner from './images/spinner-animated.svg';

const spin = (<img style={{position:'absolute',width:'80px',height:'80px',left:'100px',top:'10px'}} src={spinner} />);

const kInstantaneousValue = 'iv';
const kMonotonicIncreasing = 'mi';

function genericGetFloatValue(row) {
  return parseFloat(row.state);
}

function makeReactVisSeriesPropsList({history, getValue=genericGetFloatValue}) {
  const dayGroups = {};
  const midnight = new Date();
  midnight.setHours(0);
  midnight.setMinutes(0);
  midnight.setSeconds(0);
  midnight.setMilliseconds(0);
  const mn_ts = midnight.getTime();
  if (!(history && history.data)) {
    return [];
  }
  let zeroTimeOffset = 0;
  history.data.map((r) => {
    const row = {
      x:r.date.getTime(),
      y:getValue(r),
    };
    let days = 0;
    while(row.x < mn_ts) {
      row.x += 86400000;
      days++;
    }

    /*
    // if not at extrema of history or end/begin of day, strip out most
    // data points for more efficient rendering.
    if (i > 2 && (i < history.data.length-3) && (r.date.getHours() > 0 && r.date.getHours() < 23)) {
      if (Math.random() > 0.1) {
        return;
      }
    }
    */

    if (row.y === undefined || isNaN(row.y)) {
      return;
    }
    if (dayGroups[days] === undefined) {
      dayGroups[days] = [];
      zeroTimeOffset = row.y;
    }
    if (history.type === kMonotonicIncreasing) {
      if (row.y < zeroTimeOffset) {
        zeroTimeOffset = row.y;
      }
      row.y -= zeroTimeOffset;
    }
    dayGroups[days].push(row);
  });
  const result = [];
  const dayProps = [
    {
      // today
      opacity:1,
      strokeWidth:3,
    },
    {
      // yesterday
      opacity:0.5,
      strokeWidth: 2,
      strokeDasharray: [3,6],
    },
    {
      // etc...
      opacity:0.50,
      strokeWidth: 2,
      strokeDasharray: [2,6],
    },
    {
      opacity:0.25,
      strokeWidth: 2,
      strokeDasharray: [1,6],
    },
  ];
  for (let days in dayGroups) {

    // series ... prefers to be [x,y] but we don't have that.
    // const dataPoints = dayGroups[days];
    // const smoother = Smooth(series,{
    //     method: Smooth.METHOD_CUBIC, 
    // });
    // const smoothed = [];
    // const newLen = 100;
    // for (let i=0;i<newLen;i++) {
    //   smoothed.push(smoother(dataPoints.length * i/newLen));
    // }
    const props = {
      data: [
        dayGroups[days][0],
        ... (LTD(dayGroups[days], 50)),
        // ... (ASAP(dayGroups[days], 50)),
        dayGroups[days][dayGroups[days].length -1],
        // ...dayGroups[days],
      ],
      color:'white',
    };
    // console.log('in',days,dayGroups[days].length,'out',props.data.length);
    if (parseInt(days)) {
      // add a 'and continue on until midnight' for past days
      // to ensure we fill the time domain, such as for solar
      // which doesn't generage value history events past ~8pm
      const dummy = {
        x:mn_ts + 86400000,
        y:dayGroups[days][dayGroups[days].length-1].y,
      };
      // dayGroups[days].push(dummy);
      props.data.push(dummy);
      const t0_dummy = Object.assign({}, props.data[0]);
      t0_dummy.x = mn_ts;
      props.data.unshift(t0_dummy);
    } else {
      // for slow-updating entities, backfill their history
      // by putting last known value in the now slot.
      const dummy = {
        x:(new Date()).getTime(),
        y:dayGroups[days][dayGroups[days].length-1].y,
      };
      // dayGroups[days].push(dummy);
      props.data.push(dummy);
    }
    result.push(Object.assign(props,dayProps[days],history.displayProps||{}));
  }

  return result;
}

function PowerRatesGraph() {
  const [tick, setTick] = useState(0);
  useInterval(() => { setTick(tick+1); }, 5*1000);

  const colors = [
    'white',
    'pink',
    'red',
  ];
  const plotProps = {
  };
  const axisStyle = {
    stroke:'pink',
    fill:'pink',
    opacity:1,
    fontWeight: 100,
    strokeWidth:1,
  };
  const label = 'Electricity Rates';
  const data = [];
  const d = new Date();
  const hour = d.getHours();
  const markHours = d.getHours() + (d.getMinutes()/60);
  d.setMinutes(1);
  d.setSeconds(0);
  d.setMilliseconds(0);
  let max = -Infinity;
  for (let h=0;h<24;h++) {
    d.setHours(h);
    const rate = resolveRate(d);
    max = Math.max(max, rate.rate);
    data.push({
      y:rate.rate,
      x: h + 0.5,
      color: colors[rate.tier],
    });
  }
  const hourMarkHeight = data[hour].y;


  return (
    <div className="Plot">
    <div className="Label">{label}</div>
    <XYPlot {...plotProps} width={255} height={100} margin={{left: 27, right: 10, top: 5, bottom: 20}} >
      <VerticalGridLines tickTotal={13} style={{...axisStyle, opacity:0.2}} />
      <XAxis tickTotal={14} style={axisStyle} tickSizeOuter={0} tickFormat={(d) => (d%12===0)?'12':(d%12)} />
      <YAxis hideLine tickTotal={4} style={axisStyle} tickSize={4} tickFormat={(d) => ((100*d).toFixed(0))+'¢'} tickSizeOuter={0} />
      <VerticalBarSeries colorType="literal" data={data} />
      <MarkSeries data={[{ y: 0, x: 0 }]} style={{display:'none'}} />
      <MarkSeries data={[{ y: 0, x: 24 }]} style={{display:'none'}} />
      <MarkSeries data={[{ y: max * 1.5, x: 0 }]} style={{display:'none'}} />
      <PolygonSeries style={{stroke:'black',fill:'black'}} data={[{x:markHours,y:hourMarkHeight},{x:(markHours-.5),y:(hourMarkHeight+.1)},{x:(markHours+.5),y:(hourMarkHeight+.1)}]} />

    </XYPlot>
    </div>
  );  
}


function Plot({label='Anon', plotProps={}, pointSets,min,max}) {
  const axisStyle = {
    stroke:'pink',
    fill:'pink',
    opacity:1,
    fontWeight: 100,
    strokeWidth:1,
  };

  // pretty terrible but alas
  // if (seriesProps.length) {
  //   for (let i=0;i<seriesProps.length;i++) {
  //     if (pointSets[i]) {
  //       for (let j=0;j<pointSets[i].length;j++) {
  //         for (let k in seriesProps[i]) {
  //           pointSets[i][j][k] = seriesProps[i][k];
  //         }
  //       }
  //     }
  //   }
  // }
  const flattened = pointSets.flat();
  const firstData = flattened && flattened.length ? flattened[0].data : [{x:0,y:0}];
  return (
    <div className="Plot">
    <div className="Label">{label}</div>
    {(pointSets.length === 0)?(spin):null}
    <XYPlot xType="time" {...plotProps} width={255} height={100} margin={{left: 27, right: 10, top: 5, bottom: 20}} >
      <GradientDefs>
        <linearGradient id="HeatGradient" x1="0" x2="0" y1="0" y2="1">
          <stop offset="0%" stopColor="red" stopOpacity={1.0} />
          <stop offset="100%" stopColor="blue" stopOpacity={1.0} />
        </linearGradient>
      </GradientDefs>

      {/* <HorizontalGridLines /> */}
      <VerticalGridLines tickTotal={13} style={{...axisStyle, opacity:0.2}} />
      <XAxis tickTotal={13} style={axisStyle} tickSizeOuter={0} tickFormat={function tickFormat(d){
          const date = new Date(d);
          return date.toLocaleTimeString().split(':')[0];
         }}
      />
      <YAxis hideLine tickTotal={4} style={axisStyle} tickSize={4} tickFormat={(d) => d.toFixed(0)} tickSizeOuter={0} />
      {flattened.map((p,i) => <LineSeries colorType="literal" key={'b'+i} {...p} />)}

      {min !== undefined ? 
        <MarkSeries data={[{ y: min, x: firstData[0].x }]} style={{display:'none'}} /> : null}
      {max !== undefined ? 
        <MarkSeries data={[{ y: max, x: firstData[firstData.length-1].x }]} style={{display:'none'}} /> : null}

    </XYPlot>
    </div>
  );  
}


function WaterHistory({entity_ids, label, days}) {
  const hists = useHistoriesArray(Object.keys(entity_ids), days);
  // if (hists && hists.data) {
  //   // console.log('updated histories',{entity_ids},hists.data.length);
  // } else {
  //   // console.log('watching histories',{entity_ids});
  // }
  hists.map(h => h.type = kMonotonicIncreasing);
  hists.map(h => h.units = 'L');

  // TODO merge?
  const pointSets = hists.map(h => makeReactVisSeriesPropsList({history:h}));

  let i = 0;
  for (let k in entity_ids) {
    // 
    if (pointSets[i] !== undefined) {
      for (let j=0; j<pointSets[i].length; j++) {
        pointSets[i][j] = Object.assign(pointSets[i][j], entity_ids[k]);
      }
    }
    i++;
  }

  return <Plot label={label} pointSets={pointSets} seriesProps={entity_ids} />;
}


function TemperatureHistory({entity_ids, label, days}) {
  const hists = useHistoriesArray(Object.keys(entity_ids), days);
  hists.map(h => h.type = kInstantaneousValue);

  const plotProps = {};
  if (label === 'Water Heater') {
    plotProps.yDomain = [60,150];
  } else {
    plotProps.yDomain = [40,85];
    // hists[0].displayProps = {color:'url(#HeatGradient)'};
    // hists.map(h => h.displayProps = {color: 'purple'});
  }

  const pointSets = hists.map(h => makeReactVisSeriesPropsList({history:h}));

  let i = 0;
  for (let k in entity_ids) {
    // 
    if (pointSets[i] !== undefined) {
      for (let j=0; j<pointSets[i].length; j++) {
        pointSets[i][j] = Object.assign(pointSets[i][j], entity_ids[k]);
      }
    }
    i++;
  }

  return <Plot label={label} plotProps={plotProps} pointSets={pointSets} />;
}


function HistoryGraph({entity_ids, label, days, min, max, plotProps={}}) {
  const hists = useHistoriesArray(Object.keys(entity_ids), days);
  hists.map(h => h.type = kInstantaneousValue);
  hists.map(h => h.units = '%');

  const pointSets = hists.map(h => makeReactVisSeriesPropsList({history:h}));

  return <Plot label={label} min={min} max={max} plotProps={plotProps} pointSets={pointSets} />;
}


function EnergyHistory({entity_ids, label, days}) {
  const hists = useHistoriesArray(Object.keys(entity_ids), days);
  hists.map(h => h.type = kMonotonicIncreasing);
  hists.map(h => h.units = 'kWh');

  // TODO merge?
  const pointSets = hists.map(h => makeReactVisSeriesPropsList({history:h}));

  let i = 0;
  for (let k in entity_ids) {
    // 
    if (pointSets[i] !== undefined) {
      for (let j=0; j<pointSets[i].length; j++) {
        pointSets[i][j] = Object.assign(pointSets[i][j], entity_ids[k]);
      }
    }
    i++;
  }


  return <Plot label={label} pointSets={pointSets} />;
}


export {
  EnergyHistory,
  TemperatureHistory,
  WaterHistory,
  PowerRatesGraph,
  HistoryGraph,
};