import date from 'date-and-time';
import { React, useEffect, useState, useCallback } from 'react';
// import { useMQTT_String } from '../useMQTT';
import { useDropzone } from 'react-dropzone';
import {
  useEnt,
} from './hooks/useHomeAssistantCore.js';


function Clock() {
  const [counter, setCounter] = useState(0);

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = () => {
      // Do whatever you want with the file contents
        const binaryStr = reader.result;
        console.log(binaryStr);
        const str = new TextDecoder().decode(binaryStr);
        console.log(str);
        const json = JSON.parse(str);
        console.log(json);
      };
      reader.readAsArrayBuffer(file);
    });
    
  }, []);
  const {getRootProps, getInputProps} = useDropzone({onDrop});

  const alexa_ent = useEnt('sensor.kitchen_orb_next_timer');

  let delta = 0;
  if (alexa_ent?.state) {
    const dt = new Date(alexa_ent.state);
    // const dt = new Date('2024-03-0400:27:29+00:00');
    const now = new Date();
    delta = dt.getTime() - now.getTime();
    if (isNaN(delta) || delta<0) {
      delta = 0;
    } else {
      delta = Math.floor(delta / 1000);
    }
  }


  const interval = delta > 0 ? 1 : 15;
  useEffect(() => {
    const iv = setInterval(() => {
      setCounter(counter + 1);
    }, 1000 * interval);

    return () => {
      clearInterval(iv);
    };
  },[interval, counter]);

  // const time = useMQTT_String('homeassistant/sensor/time/state');
  const time = 'errorwtf';
  return (
    <div {...getRootProps()} className="Clock" style={{marginBottom:0}} title={time}>
      <div className="metric xlarge" style={{textAlign:'center'}} suppressHydrationWarning>
        {(delta>0) ? <Delta delta={delta} /> : <Times />}
      </div>
      <input {...getInputProps()} />
    </div>  
  );
}

function Times() {
  return (
    <>
    {date.format(new Date(), 'dddd M/D').replace('urday','').replace('nes','').replace(/s?day/,'')}
    {' '}{date.format(new Date(),'h')}<Colon />{date.format(new Date(), 'mm A')}
    </>
  );
}
function Delta({delta}) {

  const mins = (Math.floor(delta/60));
  const secs = delta - mins*60;

  // const secs = delta/60 - mins;
  return (
    <>
    <span style={{color:'black'}}>{mins.toFixed(0)}:{secs.toFixed(0).padStart(2,'0')}</span>
    {' '}{date.format(new Date(),'h')}<Colon />{date.format(new Date(), 'mm A')}
    </>
  );
}

function Colon() {
  const [counter, setCounter] = useState(0);
  const interval = 1;
  useEffect(() => {
    const iv = setInterval(() => {
      setCounter(counter + 1);
    }, 1000 * interval);

    return () => {
      clearInterval(iv);
    };
  },[interval, counter]);
  return (
   <div style={{display:'inline-block',color:(counter % 2 ? 'white':'pink')}}>:</div>
  );
}

export default Clock;