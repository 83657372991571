
// ugh these are terrible.

const utlityRates = {
  offpeak: {
    wdhours:[0,7],
    wkhours:[0,15],
    gen:0.08486,
    trans:0.10493,
    dist:0.07054,
    name: 'Off-peak',
    tier: 0,
  },
  partpeak: {
    wdhours:[7,14],
    wkhours:[25,25],
    gen:0.08486,
    trans:0.10493,
    dist:0.14212,
    name: 'Partial-peak',
    tier: 1,
  },
  peak: {
    wdhours:[14,21],
    wkhours:[15,19],
    name: 'Peak',
    trans:0.10493,
    dist:0.24928,
    gen:0.10847,
    tier: 2,
  },
  partpeak2: {
    wdhours:[21,23],
    wkhours:[25,25],
    gen:0.08486,
    trans:0.10493,
    dist:0.14212,
    name: 'Partial-peak',
    tier: 1,
  },
  offpeak2: {
    wdhours:[23,24],
    wkhours:[19,24],
    gen:0.08486,
    trans:0.10493,
    dist:0.07054,
    name: 'Off-peak',
    tier: 0,
  },
};

function resolveRate(date) {
  const weekend = (date.getDay() === 0 || date.getDay() === 6);
  const hour = date.getHours();
  for (let k in utlityRates) {
    const r = utlityRates[k];
    if (weekend) {
      if (r.wkhours[0] <= hour) {
        if (r.wkhours[1] > hour) {
          r.rate = r.gen + r.dist + r.trans;
          r.key = k;
          return r;
        }
      }
    } else {
      if (r.wdhours[0] <= hour) {
        if (r.wdhours[1] > hour) {
          r.rate = r.gen + r.dist + r.trans;
          r.key = k;
          return r;
        }
      }
    }
  }
  return null;
}

export default resolveRate;
