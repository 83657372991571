import { useState, useEffect, useSyncExternalStore } from 'react';
import PubSub from 'PubSub';
import ReconnectingWebSocket from 'reconnecting-websocket';

import useFetch from 'use-http';

let kReloadScene = 'scene.reload_screens';

let geEventBufferDepth = 10;
let gEventBuffer = [];
let kEventBufferId = 'k_event_buffer';

let gCommandSequence = 1;
let ws = null;
let ps = new PubSub();
let psh = new PubSub();
let gWorld = {};
let gEnts = {};
let gDevs = {};
let gAreaMaps = {
  areas: {},
  id_to_area: {},
  area_to_id: {},
  ordered: [],
};
let gAreas = {};
let gIdToArea = {};
let gAreaToId = {};
let gWorldKeys = [];
let gRxHandlers = {};
let gWorldHistory = {};
let gNeedHistory = {};
let gHasHistory = {};
let gHistoryQueue = [];


/*
setInterval(() => {
  const key = 'sensor.esphome_web_a8c7c4_total_dispensed_hot_water';
  const has = psh.hasSubscribers(key);
  console.log('Subs:',key,has);
},100);
*/

async function triggerHistoryQueue() {
  let batch = 20;
  while (gHistoryQueue.length && batch>0) {
    const [id,days] = gHistoryQueue.pop();
    await genFetchHistory(id,days);
    // console.log('queue now',gHistoryQueue.length,gHistoryQueue);
    batch--;
  }
  if (gHistoryQueue.length) {
    historyQueueReschedule();
  }
}
let historyTimeout = undefined;
function historyQueueReschedule() {
  if (historyTimeout) {
    clearTimeout(historyTimeout);
  }
  historyTimeout = setTimeout(async () => {
    await triggerHistoryQueue();
  }, 100);
}

// setInterval(async () => {
//   await triggerHistoryQueue();
// }, 1000);

const kBearerToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJjYzc2ZmIyY2Y0MzA0YjVhYmVjYmI2ZGY1NTE5M2ZkMCIsImlhdCI6MTY4OTIwNDA5NCwiZXhwIjoyMDA0NTY0MDk0fQ.OxwrE4gb8PYS-sQQHDEMhSxqeGmY_LiFfIDQMIvWbrI';
const kIdSubscriptionKey = '_ids';
const kDeviceSubscriptionKey = '_ds';
const kEntitySubscriptionKey = '_es';
const kAreaSubscriptionKey = '_as';
const kIdChanged = '_ic';
const kHistoryDaysLimit = 3;
let oneshot_alert = true;

const kIgnorePatterns = [
  /^camera\..*proxy/,
];

const haConfig = {
  urlBase: 'https://ha.3754.xyz/',
  wsBase: 'wss://ha.3754.xyz/api/websocket',
  // headers: new Headers({
  //   Authorization: `Bearer ${kBearerToken}`,
  // }),
  headers: {
    Authorization: `Bearer ${kBearerToken}`,
  },
  preferredAreas: [
    'kitchen',
    'basement',
    'laundry',
    'alex_bedroom',
    'kate_bedroom',
    'zoe_room',
    'parents_room',
    'living_room',
    'dining_room',
    'office',
    'garage',
  ],
};

const getFetchArguments = () => {
  return {
    method: 'GET',
    headers: haConfig.headers,
  };
};


function wsSendCommand(command, handler = undefined, oneShot = true) {
  const payload = Object.assign({id:gCommandSequence},command);
  if (handler) {
    gRxHandlers[gCommandSequence] = (blob) => {
      handler(blob);
      if (oneShot) {
        delete(gRxHandlers[blob.id]);
      }
    };
  }
  gCommandSequence++;
  ws.send(JSON.stringify(payload));
}

(() => {

  // Reload the page so we get new tokens for cameras? This fires for sleep/wake and browser
  // hide/show on dev mode at least. also catches us up if asleep while states changed.
  if (process.env.NODE_ENV !== 'production') {
    setTimeout(() => {
      document.addEventListener('visibilitychange', (e) => {
        console.log('reloading because our dev visibility changed.');
        window.location.reload();
      });
    },1000);
  }


  let service_data;

  if (ws !== null) {
    return;
  }
  ws = new ReconnectingWebSocket(haConfig.wsBase);
  ws.onerror = function(e)  {
    console.log('wserror',e);
  };
  ws.onmessage = function(message) {
    const blob = JSON.parse(message.data);

    switch (blob.type) {
      case 'auth_required':
        ws.send(JSON.stringify({
          type: 'auth',
          'access_token': kBearerToken,
        }));
        break;
      case 'auth_ok':
        wsSendCommand({type:'get_states'}, (blob) => {
          // const history = {};
          // Bunch of states arriving as part of startup.
          const informed_of_entity_ids = [];
          blob.result.map(obj => {
            if (!gWorld[obj.entity_id]) {
              // a new object so useSyncExternalStore works
              gWorldKeys = [...gWorldKeys,obj.entity_id];
            }
            gWorld[obj.entity_id] = obj;
            // for (let d = 0;d<=kHistoryDaysLimit;d++) {
            //   const k = d + '_' + obj.entity_id;
            //   if (gWorldHistory[k] === undefined) {
            //     gWorldHistory[k] = {
            //       days:d,
            //       loaded:false,
            //       entity_ids:[obj.entity_id],
            //       data:[],
            //       this_is:'history',
            //     };
            //   }
            // }
            // history[obj.entity_id] = [{state:obj.state,last_changed:obj.last_changed}];

            // ps.publish(obj.entity_id, true);
            // psh.publish(obj.entity_id, true);

            // psh.publish(kIdChanged, obj.entity_id);
            // ps.publish(kIdChanged, obj.entity_id);

            // note there can be a lag between when we first hear about a device/entity
            // which happens here
            // and when we get area/conf info and then subscribe to change events
            // so maybe we should defer publishing these kIdChanged events until
            // that process is done? or republish them?
            informed_of_entity_ids.push(obj.entity_id);
          });
          // ps.publish(kIdChanged, informed_of_entity_ids);
          // psh.publish(kIdChanged, informed_of_entity_ids);
          // console.log('informed_of_entity_ids',informed_of_entity_ids.length);

          // wsSendCommand({type:'get_services'}, (blob) => {
          //   console.log('get_services',blob);
          // });
          // wsSendCommand({type:'get_config'}, (blob) => {
          //   console.log('get_config',blob);
          // });

          wsSendCommand({type:'config/area_registry/list'}, (blob) => {
            blob.result.map(r => gAreas[r.area_id] = r);

            wsSendCommand({type:'config/device_registry/list'}, (blob) => {
              blob.result.map(r => gDevs[r.id] = r);
              ps.publish(kDeviceSubscriptionKey, null);

              wsSendCommand({type:'config/entity_registry/list'}, (blob) => {
                blob.result.map(r => gEnts[r.entity_id] = r);
                ps.publish(kEntitySubscriptionKey, null);

                blob.result.map(r => {
                  const cEnt = gEnts[r.entity_id];
                  if (cEnt) {
                    const cDev = gDevs[cEnt.device_id];
                    if (cDev) {
                      gIdToArea[r.entity_id] = cEnt.area_id || cDev.area_id;
                    }
                  } else {
                    console.log('err ',r.entity_id);
                  }
                });

                Object.keys(gIdToArea).map(k => {
                  if (!gAreaToId[gIdToArea[k]]) {
                    gAreaToId[gIdToArea[k]] = [];
                  }
                  gAreaToId[gIdToArea[k]].push(k);
                });

                let orderedAreas = Object.keys(gAreas)
                  .sort((a,b) => gAreas[a].name <= gAreas[b].name ? -1 : 1);
                const combinedAreas = [...haConfig.preferredAreas];
                orderedAreas.map(a => {
                  if (!combinedAreas.includes(a)) {
                    combinedAreas.push(a);
                  }
                });
                gAreaMaps = {
                  areas: Object.assign({},gAreas),
                  id_to_area: Object.assign({}, gIdToArea),
                  area_to_id: Object.assign({}, gAreaToId),
                  ordered: combinedAreas,
                };
                ps.publish(kAreaSubscriptionKey, null);

                wsSendCommand({type:'subscribe_events'}, () => {
                  // done - all subscribed.

                  // this is a follow-up publish of the things we already knew about from 
                  // the get_states call which happened first.
                  for (let i=0;i<informed_of_entity_ids.length;i++) {
                    const entity_id = informed_of_entity_ids[i];
                    ps.publish(entity_id, true);
                    // ps.publish(kIdChanged, entity_id);
                    psh.publish(entity_id, true);
                    // psh.publish(kIdChanged, entity_id);
                  }
                  ps.publish(kIdChanged, informed_of_entity_ids);
                  psh.publish(kIdChanged, informed_of_entity_ids);

                  ps.publish(kIdSubscriptionKey,gWorldKeys);
                });
              });
            });
          });

        });
        break;
      case 'auth_invalid':
        console.log('ws auth failure');
        break;
      case 'result':
        if (gRxHandlers[blob.id.toString()]) {
          gRxHandlers[blob.id](blob);
        } else {
          console.log('ws result, no handler',blob);
        }
        break;
      case 'event':
        // an incremental update happening after we've been loaded for a while.
        switch (blob.event.event_type) {
          case  'state_changed':
            // console.log('event:',blob.event.data.entity_id,blob.event);
            // eslint-disable-next-line
            const id = blob.event.data.entity_id;
            // eslint-disable-next-line
            const new_state = blob.event.data.new_state;
            // TODO FIXME psh.hasSubscribers(id) doesn't do what I expect
// console.log('state_changed',{id,new_state,subs:psh.hasSubscribers(id)});
            gWorld[id] = Object.assign({}, new_state);
            // eslint-disable-next-line
            if (new_state && new_state.state && gHasHistory[id]) {
              const historyEntry = {
                state:new_state.state,
                last_changed: new_state.last_changed,
                date: new Date(new_state.last_changed),
              };
              if (getIdNeedsAttributeHistory(id)) {
                historyEntry.attributes = new_state.attributes;
              }
              // console.log('interesting change',id,historyEntry);
              // new object needed here too.
              let did_update_history = false;
              for (let d = 0;d<=kHistoryDaysLimit;d++) {
                const k = d + '_' + id;
                // only if someone has already been listening for it.
                if (gWorldHistory[k]) {
                  gWorldHistory[k].data = [...(gWorldHistory[k].data),historyEntry];
                  did_update_history = true;
                }
              }
              // console.log({did_update_history});
              if (did_update_history) {
                psh.publish(id, null);//gWorldHistory[id]);
                psh.publish(kIdChanged,id);
              }
            // } else {
              // console.log('uninteresting change',id,blob.event);           
            }
            ps.publish(id, null);//gWorld[id]);
            ps.publish(kIdChanged,id);
            break;
          case 'recorder_5min_statistics_generated':
            // ignore
            break;
          case 'recorder_hourly_statistics_generated':
            // ignore
            break;
          case 'entity_registry_updated':
            // mostly on ios app launch
            break;
          case 'ios.entered_background':
          case 'ios.became_active':
            break;
          case 'area_registry_updated':
            // huh, should probably refetch here. interesting.
            console.log('area_registry updated');
            break;
          case 'call_service':
            if (blob.event.data && blob.event.data.domain === 'scene') {
              service_data = blob.event.data.service_data;
console.log({service_data});
              if (Array.isArray(service_data.entity_id)) {
                console.log('ok entity_id');
                if (service_data.entity_id.indexOf(kReloadScene) !== -1) {
                  console.log('ok entity_id array');
                  window.location.reload();
                  return;
                }
              } else {
                switch (service_data.entity_id) {
                  case kReloadScene:
                    console.log('ok entity_id not array');
                    window.location.reload();
                    break;
                  default:
                    break;
                }
              }
            }
            break;
          case 'open_epaper_link_event':
            // ignore
            break;
          case 'automation_triggered':
            // TODO enhancement: put up a toast?
            break;
          case 'service_registered':
            // example encountered was '.data.service = 'alexa_media_last_called' but idk
            break;
          case 'service_removed':
            // stopped music on echo
            break;
          case 'lutron_caseta_button_event':
            // oh interesting... 
            // action: "press",
            // area_name: "Living Room",
            // button_number: 2,
            // button_type: "on",
            // device_id: "0ce689cbcacd5561be0a622ff7912b8b",
            // device_name: "Pico",
            // leap_button_number: 0,
            // serial: 92780219,
            // type: "Pico3ButtonRaiseLower",
            break;
          case 'event_mqtt_reloaded':
          case 'themes_updated':
          case 'scene_reloaded':
          case 'automation_reloaded':
          case 'event_template_reloaded':
          case 'lovelace_updated':
            // reboot/reload lifecycle
            break;
          default:
            console.log('ws event_type unhandled',blob);
            break;
        }
        break;
      default:
        console.log('ws unhandled',blob);
        break;
    }
  };
})();

// TODO enhancement - some bigger rule set for complex objects/devices?
// current state of heating/cooling/idle is stored in attributes for these.
function getIdNeedsAttributeHistory(id) {
  return id.match(/climate/);  
}


function useURL(pathAndQuery) {
  const fullPath = `${haConfig.urlBase}api/${pathAndQuery}`;
  const { loading, error, data, get } = useFetch(
    fullPath,
    {
      cacheLife: 5000,
      cachePolicy: 'no-cache',
      cache: 'no-cache',
      persist: false,
      interceptors: {
        // gross.
        request: async ({ options, url, path, route }) => {
          return Object.assign(options, getFetchArguments(),{method:'GET'});
        },
      },
    },
    [], // ?
  );
  return { loading, error, data, get };
}

async function genFetchHistory(id,days) {
  if (days === null || days === undefined) {
    console.log('no days?',days);
  }
  const fetchData = async () => {
    let err = null;
    let start = '';
    const today = new Date();

    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);
    today.setMilliseconds(0);
    start = (new Date(today.getTime() - days*86400000)).toISOString();

    const end_time = (new Date()).toISOString();

    const fetch_attributes = getIdNeedsAttributeHistory(id);

    const attributes_param = fetch_attributes ? '' : '&no_attributes';

    const response = await fetch(
      `${haConfig.urlBase}api/history/period/${start}?end_time=${end_time}&minimal_response&filter_entity_id=${id}${attributes_param}`,
      getFetchArguments(),
    ).catch(e => { err=e;console.log(id,e); });

    if (err) {
      console.log(err);
      return;
    }
    const json = await response.json();
    if (!(json && json[0])) {
      console.log('history fetch failed',json,id,days);
      return;
    }

    const hKey = days+'_'+id;
    const newHistory = [];
    const useInSet = false;
    if (useInSet) {
      // what is inSet doing here? Trying to dedupe or something?
      const inSet = {};
      if (gWorldHistory[hKey]) {
        // console.log('gWorldHistory has id',id,gWorldHistory[hKey]);
        gWorldHistory[hKey].data.map(r => inSet[r.last_changed] = r.state);
        // console.log('copied to inSet',inSet);
      } else {
        // why would this happen? 
        gWorldHistory[hKey] = {days:days,loaded:false,data:[],entity_ids:[id],this_is:'history'};
        // console.log('gWorldHistory had no id',id);
      }
      // console.log('json[0]',json[0]);
      json[0].map(r => inSet[r.last_changed] = r.state);
      // console.log('inSet',inSet);
      Object.keys(inSet)
        .sort((a,b) => a<b?-1:1)
        .map(k => newHistory.push({state:inSet[k], last_changed:k, date:new Date(k)}));
      gWorldHistory[hKey].data = newHistory;
      gWorldHistory[hKey].loaded = true;
    } else {
      json[0].map(r => {
        const datum = {
          state: r.state,
          last_changed: r.last_changed,
          date: new Date(r.last_changed),
        };
        if (fetch_attributes) {
          datum.attributes = r.attributes;
        }
        newHistory.push(datum);
      });
      gWorldHistory[hKey] = {days:days,loaded:true,data:newHistory,entity_ids:[id],this_is:'history'};
    }

    // console.log('ok done',gWorldHistory[hKey]);
    psh.publish(id, gWorldHistory[hKey]);
    psh.publish(kIdChanged, id);
  };
  await fetchData();
}

function extractHistory(id,days) {
  const hKey = days+'_'+id;
  if (oneshot_alert && days > kHistoryDaysLimit) {
    oneshot_alert = false;
    alert(`Illegal history days ${days} for id ${id} (max ${kHistoryDaysLimit})`);
  }
  if (!gNeedHistory[hKey]) {
    gNeedHistory[hKey] = true;
    // console.log('queued?',gHistoryQueue.indexOf([id,days]));
    gHistoryQueue.push([id,days]);
    historyQueueReschedule();
  }
  if (gWorldHistory[hKey]) {
    gHasHistory[id] = true;
    return Object.assign({}, gWorldHistory[hKey]);
  }
  return undefined;
}

function useHistory(id,days=0) {
  const [value, setValue] = useState(extractHistory(id,days));

  useEffect(() => {
    let sub = null;
    const bound = (json) => {
      try {
        setValue(extractHistory(id,days,true));
      } catch (e) {
        console.log('failed to extract',id,e,json);
      }
    };
    sub = psh.subscribe(id, bound);
    return () => {
      psh.unsubscribe(sub);
    };
  }, [value, id, days]);

  return value;
}

function getHistories(entity_ids, days) {
  const result = {};
  entity_ids.map(id => {
    const history = extractHistory(id,days,true);
    if (history !== undefined) {
      result[id] = history;
    }
  });
  return result;
}

function useHistoriesArray(entity_ids, days=0) {
  const histories = useHistories(entity_ids, days);
  return Object.values(histories);
}

function useHistories(entity_ids, days=0) {
  const [value, setValue] = useState(0);

  useEffect(() => {
    let sub = null;
    const bound = (changed_ids) => {
      if (!entity_ids.some(e => changed_ids.includes(e))) {
        return;
      }
      setValue(value + 1);
    };
    sub = psh.subscribe(kIdChanged, bound);
    return () => {
      psh.unsubscribe(sub);
    };
  }, [entity_ids, value]);

  return getHistories(entity_ids, days);
}




const findSub = (cb) => {
  const sub = ps.subscribe(kIdSubscriptionKey,cb);
  return () => ps.unsubscribe(sub);
};
const findGet = () => {
  return gWorldKeys;
};

function useFind(pattern) {
  const keys = useSyncExternalStore(findSub, findGet);
  return keys.filter(k => k.match(pattern));
}


const areaSub = (cb) => {
  const sub = ps.subscribe(kAreaSubscriptionKey,cb);
  return () => ps.unsubscribe(sub);
};
const areaGet = () => {
  return gAreaMaps;
};

function useAreas() {
  return useSyncExternalStore(areaSub, areaGet);
}

const subgets = {};
// const dummy = [];

const subget = (id) => {
  const pubs = ps;
  const key = '-' + id;

  if (subgets[key]) {
    return subgets[key];
  }

  const sub = (cb) => {
    const subNum = pubs.subscribe(id, cb);
    return () => pubs.unsubscribe(subNum);
  };
  const get = () => {
    return gWorld[id];
  };
  subgets[key] = {sub,get};
  return {sub,get};
};
// const subget = (id, wantHistory = false, actuallyWantHistory=true) => {
//   const pubs = wantHistory ? psh : ps;
//   const key = (wantHistory ? (actuallyWantHistory?'_':'/') : '-') + id;
// 
//   if (subgets[key]) {
//     return subgets[key];
//   }
// 
//   if (wantHistory && actuallyWantHistory && !gNeedHistory[id]) {
//     gNeedHistory[id] = true;
//     gHistoryQueue.push(id);
//   }
// 
//   const sub = (cb) => {
//     const subNum = pubs.subscribe(id, cb);
//     return () => pubs.unsubscribe(subNum);
//   };
//   const get = () => {
//     return wantHistory ? (actuallyWantHistory?gWorldHistory[id]:dummy) : gWorld[id];
//   };
//   subgets[key] = {sub,get};
//   return {sub,get};
// };



function useLog() {
  const sg_ent = subget(kEventBufferId);
  const ent = useSyncExternalStore(sg_ent.sub, sg_ent.get);
  return ent;
}

function useEnt(entity_id) {
  const sg_ent = subget(entity_id);

  const ent = useSyncExternalStore(sg_ent.sub, sg_ent.get);

  if (!ent) {
    return null;
  }

  if (ent.state === 'unavailable') {
    return null;
  }

  const updatedEnt = glossEnt(ent);

  return updatedEnt;
}

function glossEnt(ent) {
  for (let k=0;k<kIgnorePatterns;k++) {
    if (ent.entity_id.match(kIgnorePatterns[k])) {
      return null;
    }
  }
  const ent_conf = gEnts[ent.entity_id];
  if (ent_conf) {
    ent.config = ent_conf;
  } else {
    // console.log('no ent conf for',ent.entity_id,ent);
    ent.config = {};
  }
  if (!ent_conf || !ent_conf.device_id) {
    // console.log('No ent config device_id',ent.entity_id,ent_conf);
    // return null;
  }

  if (ent_conf && ent_conf.device_id) {
    const dev_conf = gDevs[ent_conf.device_id];
    if (dev_conf) {
      ent.device = dev_conf;
    } else {
      // console.log('no device conf for',ent.entity_id,ent);
      return null;
    }
  }

  if (ent.device && ent.device.disabled_by) {
    return null;
  } else if (ent.config && (ent.config.disabled_by || ent.config.hidden_by)) {
    return null;
  }

  // device has an area
  if (ent.device && ent.device.area_id) {
    ent.area_id = ent.device.area_id;
  }
  // ... but config (ent-specific) takes priority
  if (ent.config.area_id) {
    ent.area_id = ent.config.area_id;
  }

  ent.area = gAreas[ent.area_id] || {area_id:'none',name:'None'};
  return ent;
}

function getEnts(entity_ids) {
  const result = [];
  if (entity_ids.length === 0) {
    console.error('nothing requested');
  }
  entity_ids.map(i => {if (gWorld[i]) {result.push(gWorld[i]);}});
  if (entity_ids.length > result.length) {
    // console.error('getEnts requested',entity_ids.length,'result',result.length,entity_ids);
  } else {
    // console.log('getEnts requested',entity_ids.length,'result',result.length,entity_ids);
  }
  return result.map(e => glossEnt(e)).filter(e => e);
}

function useEnts(entity_ids, keepUpdated=true) {
  const [value, setValue] = useState(0);

  useEffect(() => {
    if (!keepUpdated) {
      return () => null;
    }
    if (!entity_ids.length) {
      return () => null;
    }
    let sub = null;
    const bound = (changed_ids) => {
      if (!entity_ids.some(e => changed_ids.includes(e))) {
        return;
      }
      setValue(value + 1);
    };
    sub = ps.subscribe(kIdChanged, bound);
    return () => {
      ps.unsubscribe(sub);
    };
  }, [entity_ids,keepUpdated,value]);

  if (!entity_ids.length) {
    return [];
  }

  return getEnts(entity_ids);
}


window.gWorldSnapshot = (id) => {
  return {
    gWorld,
    gWorldHistory,
  };
};

window.gWorldGet = (id) => {
  return gWorld[id];
};

window.gWorldFind = (pattern) => {
  return gWorldKeys.filter(k => k.match(pattern));
};

export {
  useEnt,
  useEnts,
  useAreas,
  useHistory,
  useHistories,
  useHistoriesArray,
  useFind,
  useLog,
  getEnts,
  useURL,
  wsSendCommand,
  haConfig,
};
export default useEnt;
