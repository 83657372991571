import { React, useState } from 'react';
import {
  // useEnt,
  useEnts,
  useFind,
  useHistory,
  useHistories,
} from './hooks/useHomeAssistantCore.js';

// import {
//   useSensorsWithDeviceClass,
// } from './hooks/useHomeAssistant.js';

// import {
//   Entity,
// } from './Entities.jsx';

import resolveRate from './utilityRates.js';

import spinner from './images/spinner-animated.svg';

import { Chart } from 'react-google-charts';

import powerData from './power.json';

import {
  exteriorAreaIds,
  powerSinksAndSources,
  energySinksAndSources,
} from './houseConfig';

const options = {
  sankey: {
    node: {
      nodePadding: 10,
      width: 100,
      label: {
        fontName: 'SF UI Display',
        fontSize: 20,
        color: '#fff',
        bold: true,
        italic: false,
      },
    },
  },
};


function EnergyDiagram() {
  const ids = Object.values(energySinksAndSources).map(v => Object.values(v)).flat().flat();
  const histories = useHistories(ids);
  // console.log(histories);
  const powerOptions = {
    sankey: {
      link: {
        color: { fill: 'purple' },
      },
      animation:{
        duration: 1000,
        easing: 'in',
      },

      node: {
        colors: ['pink'],
        nodePadding: 10,
        width: 25,
        label: {
          fontName: 'SF UI Display',
          fontSize: 10,
          color: '#fff',
          bold: true,
          italic: false,
        },
      },
    },
  };

  Object.keys(histories).map(k => histories[k].data = histories[k].data.filter(r => !isNaN(parseFloat(r.state))));

  const energies = {};
  const kLoadName = ' Energy Today ';
  const data = [['To','From','Weight']];
  let total_in = 0;
  let total_out = 0;
  for (let j in energySinksAndSources) { 
    for (let k in energySinksAndSources[j]) {
      let energy = 0;
      energySinksAndSources[j][k].map(id => {
        if (histories[id]) {
          // bin
          if (j === 'sources') {
            const bins = {};
            const keys = {};
            const usages = {};
            const rated = histories[id].data.map(r => {
              const rate = resolveRate(r.date);
              keys[rate.key] = rate.name;
              if (bins[rate.key] === undefined) {
                bins[rate.key] = [];
              }
              const value = parseFloat(r.state);
              if (!isNaN(value)) {
                bins[rate.key].push(value);
              }
            });
            for (let rk in keys) {
              if (bins[rk].length < 2) {
                continue;
              }
              const in_e = bins[rk][0];
              const out_e = bins[rk][bins[rk].length - 1];
              const delta = out_e - in_e;
              if (usages[keys[rk]] === undefined) {
                usages[keys[rk]] = 0;
              }
              // console.log(id, {rk,in_e,out_e,delta});
              usages[keys[rk]] += delta;
            }
            for (let uk in usages) {
              data.push([k + ' - ' + uk, kLoadName, usages[uk]]);
            }
          }
          if (histories[id].data.length === 0) {
            return;
          }
          const in_e = parseFloat(histories[id].data[0].state);
          const out_e = parseFloat(histories[id].data[histories[id].data.length - 1].state);
          const delta = out_e - in_e;
          if (!isNaN(delta)) {
            energy += delta;
          }
        }
      });
      energies[k] = energy;
      if (j === 'sources') {
        // data.push([k, kLoadName, energy]);
        total_in += energy;
      } else if (j === 'sinks') {
        data.push([kLoadName, k, energy]);
        total_out += energy;
      } else if (j === 'overall_sink') {
        // data.push()
      }
    }
  }

  if (total_out < total_in) {
    data.push([kLoadName, 'Other/Untracked', total_in - total_out]);
  } else {
    data.push(['Other/Untracked', kLoadName, total_out - total_in]);
  }

  const filteredData = data.filter((e,i) => i === 0 || e[2] > .1);

  return (
    <div className='app' style={{margin:20,width:400}}>
      <Chart
        chartType='Sankey'
        data={filteredData}
        options={powerOptions}
      />
    </div>
  );
}

function PowerDiagram() {
  const powerOptions = {
    sankey: {
      link: {
        color: { fill: 'purple' },
      },
      animation:{
        duration: 1000,
        easing: 'in',
      },

      node: {
        colors: ['pink'],
        nodePadding: 10,
        width: 25,
        label: {
          fontName: 'SF UI Display',
          fontSize: 10,
          color: '#fff',
          bold: true,
          italic: false,
        },
      },
    },
  };

  let entity_ids = [];
  for (let k in powerSinksAndSources) {
    for (let j in powerSinksAndSources[k]) {
      entity_ids = [...entity_ids, ... powerSinksAndSources[k][j]];
    }
  }

  const ents = useEnts(entity_ids);
  // const found_ids = ents.map(e => e.entity_id);
  // const lost = entity_ids.filter(id => found_ids.indexOf(id) === -1);
  // console.log({lost});
  const light_ids = useFind(/light\./);
  const on_lights = useEnts(light_ids).filter(e => e.state === 'on');

  const powers = {};
  const names = {};

  for (let i=0; i<ents.length; i++) {
    const value = parseFloat(ents[i].state);
    if (!isNaN(value)) {
      let mult = 1;
      if (ents[i].attributes && ents[i].attributes.unit_of_measurement === 'W') {
        mult = 1;
      } else if (ents[i].attributes && ents[i].attributes.unit_of_measurement === 'kW') {
        mult = 1000;
      }
      powers[ents[i].entity_id] = value * mult;
      if (ents[i].attributes && ents[i].attributes.friendly_name) {
        names[ents[i].entity_id] = ents[i].attributes.friendly_name
          .replace(/kc868-m16-(sub)?panel/, '')
          .replace(/ and /,'/')
          .replace(/ Outlets/,'')
          .replace(/Power -?/,'');
      }
    } else {
      powers[ents[i].entity_id] = 0;
    }
  }


  let totalSource = 0;
  const kLoadName = ' Power Now ';
  const data = [['To','From','Weight']];
  const secondLevel = [];
  for (let j in powerSinksAndSources.sources) {
    const row = [j, kLoadName, 0];
    for (let i=0;i<powerSinksAndSources.sources[j].length; i++) {
      const entity_id = powerSinksAndSources.sources[j][i];
      if (j === 'Battery' && powers[entity_id] < 0) {
        // console.log(j,powers[entity_id]);
        // charging, not a source
        continue;
      }
      if (powers[entity_id] && (powers[entity_id] > 0)) {
        row[2] += powers[entity_id];
        totalSource += powers[entity_id];
      }
    }
    data.push(row);
  }


  let totalSink = 0;
  for (let j in powerSinksAndSources.overall_sink) {
    for (let i=0;i<powerSinksAndSources.overall_sink[j].length; i++) {
      const entity_id = powerSinksAndSources.overall_sink[j][i];
      if (powers[entity_id] && (powers[entity_id] > 0)) {
        totalSink += powers[entity_id];
      }
    }
  }

  let totalLoad = 0;
  for (let j in powerSinksAndSources.sinks) {
    const row = [kLoadName, j, 0];
    for (let i=0;i<powerSinksAndSources.sinks[j].length; i++) {
      const entity_id = powerSinksAndSources.sinks[j][i];
      if (j === 'Battery' && powers[entity_id] > 0) {
        // console.log(j,powers[entity_id]);
        // discharging, not a load
        continue;
      } else if (j === 'Battery') {
        row[2] -= powers[entity_id];
        totalLoad -= powers[entity_id];
      }
      if (powers[entity_id] && (powers[entity_id] > 0)) {
        row[2] += powers[entity_id];
        totalLoad += powers[entity_id];
      } 
      secondLevel.push([j, names[entity_id] + '   ', powers[entity_id]]);
    }
    data.push(row);
  }

  const light_powers = on_lights.map(e => powerData[e.entity_id]?.value || 0);

// console.log({powers});

  // const no_light_powers = on_lights.filter(e => !powerData[e.entity_id]?.value).map(e => e.entity_id);
  const light_power = light_powers.reduce((a, b) => a + b, 0);
  // console.log({light_powers,light_power,on_lights});
  const num_on_label = `${on_lights.length} Light${on_lights.length===1?'':'s'}`;
  // data.push([kLoadName, 'Lights ', light_power]);
  data.push([kLoadName, num_on_label, light_power]);
  totalLoad += light_power;
  // on_lights.map(e => secondLevel.push([num_on_label, e?.attributes?.friendly_name || e.entity_id, powerData[e.entity_id]?.value || 0]));
  const areas = {};
  let indoor = 0;
  let outdoor = 0;
  let n_i = 0;
  let n_o = 0;
  // on_lights.map(e => areas[e?.area?.name] = (areas[e?.area?.name] || 0) + (powerData[e.entity_id]?.value || 0)); = 0;
  on_lights.map(e => { if (exteriorAreaIds.indexOf(e.area?.area_id) === -1) { n_i++;indoor += powerData[e.entity_id]?.value||0;} else { n_o++;outdoor += powerData[e.entity_id]?.value||0; }});
  // Object.keys(areas).map(k => secondLevel.push([num_on_label, k, areas[k]]));
  // secondLevel.push(['Lights ', ,light_power]);
  if (indoor) {
    secondLevel.push([num_on_label,`${n_i} Indoor Lights` ,indoor]);
  }
  if (outdoor) {
    secondLevel.push([num_on_label, `${n_o} Outdoor Lights` ,outdoor]);
  }
    // console.log({indoor,outdoor});
  
  const sourceDiff = Math.abs(totalSink - totalSource);
  const loadDiff = Math.abs(totalSink - totalLoad);
  const crossDiff = Math.abs(totalSource - totalLoad);

  const kUnaccountedSource = 'Other ';
  const kUnaccountedLoad = 'Other   ';
  const kUnaccountedFinalLoad = 'Other    ';
  if (totalLoad < totalSource) {
    // data.push([kLoadName,'Unk/Trans/Reac Load',sourceDiff]);
    // data.push([kLoadName,'Unk/Trans/Reac Load  ',loadDiff]);
    data.push([kLoadName,kUnaccountedLoad,crossDiff]);
    secondLevel.push([kUnaccountedLoad,kUnaccountedFinalLoad,crossDiff]);
  } else {
    // data.push(['Unk/Trans/Reac Src',kLoadName,loadDiff]);
    // data.push(['? Unk/transient AS ',kLoadName,sourceDiff]);
    data.push([kUnaccountedSource,kLoadName,crossDiff]);
  }


  const kMinPower = 10;
  const filterFn = (r,i) => {
    return (i===0 || (r[2]>kMinPower));
  };

  const dressedLocalData = data.filter(filterFn);
  const droppedLoad = data.filter((r,i) => !filterFn(r,i) && r[0] === kLoadName && r[2]>0);
  let other = 0;
  droppedLoad.map(r => other += r[2]);
  // dressedLocalData.push([kLoadName, 'Other', other]);

  dressedLocalData.map((r,i) => {if (r[0]===kUnaccountedSource) { dressedLocalData[i][2] += other; }});
  dressedLocalData.map((r,i) => {if (r[1]===kUnaccountedLoad) { dressedLocalData[i][2] += other; }});

  for (let i=1;i<dressedLocalData.length;i++) {
    if (dressedLocalData[i][0] === kLoadName) {
      for (let j=0;j<secondLevel.length;j++) {
        if (secondLevel[j][0] === dressedLocalData[i][1]) {
          if (secondLevel[j][0] && dressedLocalData[i][2]) {
            secondLevel[j][0] += ' ' + dressedLocalData[i][2].toFixed(0) + ' W';
          }
          if (secondLevel[j][1] && secondLevel[j][2]) {
            secondLevel[j][1] += ' ' + secondLevel[j][2].toFixed(0) + ' W';
          }
        }
      }
      dressedLocalData[i][1] += ' ' + dressedLocalData[i][2].toFixed(0) + ' W';
      // dressedLocalData[i][0] = kLoadName + ' ' + totalSink.toFixed(0) + ' W';
    } else if (dressedLocalData[i][1] === kLoadName) {
      dressedLocalData[i][0] += ' ' + dressedLocalData[i][2].toFixed(0) + ' W';
      // dressedLocalData[i][1] = kLoadName + ' ' + totalSink.toFixed(0) + ' W';
    }
  }

  secondLevel.filter((r,i) => r[2]>kMinPower).map(r => dressedLocalData.push(r));

  // console.log(dressedLocalData.map(a => a.join(' ')).join('\n'));

  if (dressedLocalData.length === 1) {
    dressedLocalData.push(['Sources',kLoadName,100]);
    dressedLocalData.push([kLoadName,'Loads',100]);
  }

  return (
    <div className='app' style={{margin:10,width:590}}>
      <Chart
        chartType='Sankey'
        data={dressedLocalData}
        options={powerOptions}
      />
    </div>
  );
}

/*

https://ha.3754.xyz/api/history/period/2023-11-27T00:00:00+00:00?filter_entity_id=binary_sensor.meeeeeow_charger'

charger entity with attributes, not minimal_response has
info about the fast_charger_present which means we're not home
so ignore that charge session ugh yuuuuuck.

    {
      "entity_id": "binary_sensor.meeeeeow_charger",
      "state": "on",
      "attributes": {
        "charging_state": "Charging",
        "conn_charge_cable": "SAE",
        "fast_charger_present": true,
        "fast_charger_brand": "Tesla",
        "fast_charger_type": "Tesla",
        "device_class": "plug",
        "icon": "mdi:ev-station",
        "friendly_name": "Meeeeeow! Charger"
      },
      "last_changed": "2023-11-27T20:25:46.421847+00:00",
      "last_updated": "2023-11-27T20:25:46.421847+00:00"
    },

*/




function useRateBins(entity_id,last24) {
  const history = useHistory(entity_id,1);
  if (history === undefined) {
    return {
      loaded: false,
      total: 0,
      bins: {},
      costs: {},
      history: [],
      entity_id,
    };
  }
  const today = (new Date()).getDay();
  const yest_ts = (new Date()).getTime() - 86400000;
  let fh = history.data
    .filter(a => !isNaN(parseFloat(a.state)))
    .filter(a => last24 ? (a.date.getTime() > yest_ts) : (new Date(a.last_changed)).getDay() === today)
    .map(a => { return {
      state:parseFloat(a.state),
      last_changed:(new Date(a.last_changed)),
    };});


  if (entity_id.match(/^sensor\..+energy_added/)) {
    // car, non-monotonic
    for (let i=0;i<fh.length;i++) {
      if (fh[i].state === 0) {
        fh = fh.slice(i);
        break;
      }
    }
    const values = fh.map(a => a.state);
    const max = Math.max.apply(null, values);
    fh = fh.slice(0,1+values.indexOf(max));
  }


  if (fh.length <= 1) {
    return {
      loaded: false,
      total: 0,
      bins: {},
      costs: {},
      history: [],
      entity_id,
    };
  }


  const range = [fh[0].state, fh[fh.length-1].state];

  const rate_bins = {};
  const cost_bins = {};
  let startValue = fh[0].state;


  for (let i=0;i<fh.length;i++) {
    const date = fh[i].last_changed;
    const value = fh[i].state;
    const rate = resolveRate(date);
    // if (entity_id.match(/site/) && rate.name.match(/Partial/)) {
      // console.log('resolved',date,'to',rate.name);
    // }
    if (rate !== null) {
      const delta = value - startValue;
      if (delta === 0) {
        // console.log('weird, zero delta',entity_id,i,fh[i]);
        continue;
      }
      if (rate_bins[rate.name] === undefined) {
        rate_bins[rate.name] = 0;
        cost_bins[rate.name] = 0;
      }
      rate_bins[rate.name] += delta;
      cost_bins[rate.name] += delta * rate.rate;
    } else {
      console.log('null rate?',{date,value,rate});
    }
    startValue = value;
  }


//   if (entity_id.match(/_energy_added/)) {
//     console.log(entity_id,history,{range,rate_bins,cost_bins,total: range[1]-range[0]});
//   }
// 

  return {
    loaded: true,
    total: range[1]-range[0],
    bins: rate_bins,
    costs: cost_bins,
    history: fh,
    entity_id,
  };
}

// function stripBins(b, others) {
//   const out = {
//     bins: {},
//     loaded: true,
//     total: 0,
//     costs: {},
//     entity_id: 'stripped',
//   };
// 
//   for (let k in b.bins) {
//     let value = b.bins[k];
//     let cost = b.costs[k];
//     others.map(o => value -= (o.bins[k]) ? (o.bins[k]) : 0);
//     others.map(o => cost -= (o.costs[k]) ? (o.costs[k]) : 0);
//     out.bins[k] = value;
//     out.costs[k] = cost;
//     out.total += value;
//   }
//   return out;
// }

/*
function PowerFlow() {
  const solar = useEnt('sensor.powerwall_solar_now');
  const battery = useEnt('sensor.powerwall_battery_now');
  const load = useEnt('sensor.powerwall_load_now');
  const site = useEnt('sensor.powerwall_site_now');
  const wh = useEnt('sensor.esp_poe1_power');
  const m3 = useEnt('sensor.meeeeeow_charger_power');
  const my = useEnt('sensor.mom_y_charger_power');
  const network = useEnt('sensor.power_metrics_crawlspace_network');
  const fau = useEnt('sensor.kc868_m16_measured_current_13');

  const localData = [
    ['From', 'To', 'Weight'],
  ];

  if (site && site.state) {
    localData.push(['Grid','Gateway',parseFloat(site.state)]);
  }
  if (solar && solar.state) {
    localData.push(['Solar','Gateway',parseFloat(solar.state)]);
  }
  if (battery && battery.state) {
    localData.push(['Battery','Gateway',parseFloat(battery.state)]);
  }
  let accounted = 0;
  if (wh && wh.state) {
    const whkw = parseFloat(wh.state) / 1000;
    accounted += whkw;
    localData.push(['Gateway','Water Heater', whkw]);
  }
  if (m3 && m3.state) {
    const m3kw = parseFloat(m3.state);
    accounted += m3kw;
    localData.push(['Gateway','Model 3',m3kw]);
  }
  if (my && my.state) {
    const mykw = parseFloat(my.state);
    accounted += mykw;
    localData.push(['Gateway','Model Y',mykw]);
  }
  if (network && network.state) {
    const nkw = parseFloat(network.state) / 1000;
    accounted += nkw;
    localData.push(['Gateway','Data Infra',nkw]);
  }
  if (fau && fau.state) {
    const faukw = parseFloat(fau.state) * 220 / 1000;
    accounted += faukw;
    localData.push(['Gateway','FAU',faukw]);
  }

  if (load && load.state) {
    localData.push(['Gateway','House', parseFloat(load.state) - accounted]);
  }

  return (
    <div className='app' style={{margin:20,height:450,width:1216}}>
      <Chart
        chartType='Sankey'
        width='100%'
        height='400px'
        data={localData.filter((r,i) => i===0 || r[2]>0)}
        options={options}
      />
    </div>
  );
}
*/

function fakeRange(entity_id, watts, hr24) {
  const now = new Date();
  const begin = new Date(now.getTime() - 86400000);
  const rate_bins = [];
  const cost_bins = [];
  for (let i=0;i<24;i++) {
    let ts = begin.getTime() + i * 3600000;
    let d = new Date(ts);
    if (!hr24 && (d.getDay() !== now.getDay())) {
      continue;
    }
    const rate = resolveRate(d);
    if (rate === null) {
      continue;
    }
    if (rate_bins[rate.name] === undefined) {
      rate_bins[rate.name] = 0;
      cost_bins[rate.name] = 0;
    }
    const delta = watts; // have to assume 1 hour stride here
    rate_bins[rate.name] += delta;
    cost_bins[rate.name] += delta * rate.rate;
  }

  return {
    loaded: true,
    total: 0,
    bins: rate_bins,
    costs: cost_bins,
    history: [],
    entity_id,
  };
}

function EnergyFlow() {
  const [state,setState] = useState(true);
  const site = useRateBins('sensor.powerwall_site_import',state);
  // const site_export = useRateBins('sensor.powerwall_site_export',state);
  const solar_export = useRateBins('sensor.powerwall_solar_export',state);
  const battery_export = useRateBins('sensor.powerwall_battery_export',state);
  const battery_import = useRateBins('sensor.powerwall_battery_import',state);
  const load = useRateBins('sensor.powerwall_load_import',state);
  const wh = useRateBins('sensor.heat_pump_water_heater_total_energy',state);

  // const m3 = useRateBins('sensor.meeeeeow_energy_added',state,true);
  const my = useRateBins('sensor.mom_y_energy_added',state,true);
  const mx = useRateBins('sensor.bakah_energy_added',state,true);

  const ref_ll = useRateBins('sensor.left_laundry_total_energy',state,true);
  const ref_lr = useRateBins('sensor.right_laundry_total_energy',state,true);
  const hvac_up = useRateBins('sensor.upstairs_hvac_total_energy',state,true);
  const hvac_dn = useRateBins('sensor.downstairs_hvac_total_energy',state,true);
  const dw = useRateBins('sensor.dishwasher_total_energy',state,true);

  const net_sec = useRateBins('sensor.networking_and_compute_total_energy',state,true);
  // const net_sec = fakeRange('net_sec',.200,state);
  const misc_k = useRateBins('sensor.kitchen_plugs_total_energy',state,true);
  const ref_k = useRateBins('sensor.kitchen_fridge_total_energy',state,true);
  const ref_g = useRateBins('sensor.garage_fridge_and_freezer_and_outlets_total_energy',state,true);
  // const refs = fakeRange('refs',.400,state);
  // const hvac = fakeRange('hvac',2.0,state);
  // console.log({m3,my});

  const costs = {};
  // const kUsage = 'All other usage';
  const kHub = 'Gateway';

  const localDataHeader = ['From', 'To', 'Weight'];
  let localData = [];
  let accountedDemand = {};

  const incorporate = function(name, record, optionsIn={}) {
    const options = Object.assign({},{
      positiveCost:true,
      withRateName:false,
      accrueAccountedDemand:true,
      accrueCost:true,
      demand:true,
      offset:{},
    },optionsIn);

    // console.log(name,options,optionsIn);
    for (let k in record.bins) {
      let offset = 0;
      if (options.offset[k]) {
        offset = options.offset[k];
      }
      const key = name + (options.withRateName?' '+k:'');
      if (options.demand) {

        localData.push([kHub, key, record.bins[k] - offset]);
        if (options.accrueAccountedDemand) {
          if (!accountedDemand[k]) {
            accountedDemand[k] = 0;
          }
          accountedDemand[k] += record.bins[k];
        }
      } else {
        localData.push([key, kHub, record.bins[k] - offset]);
      }
      if (options.accrueCost) {
        if (!costs[key]) { costs[key] = 0; }
        costs[key] += (options.positiveCost?1:-1)*record.costs[k];
      }
    }
  };

  incorporate('Solar', solar_export, {demand:false,positiveCost:false});
  incorporate('Grid', site, {demand:false,withRateName:true});
  incorporate('Powerwall Discharge', battery_export, {demand:false,positiveCost:false,withRateName:true});
  incorporate('Powerwall Charge', battery_import);

  // incorporate('Model X', mx);
  // incorporate('Model Y', my);
  incorporate('EV Charging', mx);
  incorporate('EV Charging', my);

  incorporate('Water Heater', wh);
  incorporate('Network/Cams', net_sec);

  // incorporate('Kitchen Fridge', ref_k);
  // incorporate('Garage Fridges', ref_g);
  incorporate('Refrigeration', ref_k);
  incorporate('Refrigeration', ref_g);

  // incorporate('Laundry Left', ref_ll);
  // incorporate('Laundry Right', ref_lr);
  incorporate('Laundry', ref_ll);
  incorporate('Laundry', ref_lr);

  // incorporate('HVAC Upstairs', hvac_up);
  // incorporate('HVAC Downstairs', hvac_dn);
  incorporate('Heating & Cooling', hvac_up);
  incorporate('Heating & Cooling', hvac_dn);

  // incorporate('Dishwasher', dw);
  // incorporate('Kitchen misc', misc_k);

  incorporate('Kitchen', dw);
  incorporate('Kitchen', misc_k);

  incorporate('Unattributed Demand', load,{offset:accountedDemand,accrueAccountedDemand:false,accrueCost:false});
  

// console.log(localData);
  // const twiTier = [localData[0]];
  // for (let k in solar_export.bins) {
  //   localData.push(['Solar',kHub,solar_export.bins[k]]);
  //   if (!costs['Solar']) { costs['Solar'] = 0; }
  //   costs['Solar'] -= solar_export.costs[k];
  // }
  // for (let k in site.bins) {
  //   localData.push([k,kHub,site.bins[k]]);
  //   if (!costs[k]) { costs[k] = 0; }
  //   costs[k] += site.costs[k];
  // }
  // for (let k in site_export.bins) {
  //   localData.push(['Export',kHub,site_export.bins[k]]);
  //   if (!costs['Export']) { costs['Export'] = 0; }
  //   costs['Export'] -= site_export.costs[k];
  // }
  // for (let k in battery_export.bins) {
  //   localData.push(['Battery Charge',kHub,battery_export.bins[k]]);
  //   if (!costs['Battery Charge']) { costs['Battery Charge'] = 0; }
  //   costs['Battery Charge'] += battery_export.costs[k];
  // }
  // for (let k in battery_import.bins) {
  //   localData.push([kHub,'Battery Charge',battery_import.bins[k]]);
  //   if (!costs['Battery Discharge']) { costs['Battery Discharge'] = 0; }
  //   costs['Battery Discharge'] -= battery_import.costs[k];
  // }
  // for (let k in m3.bins) {
  //   localData.push([kHub,'Model 3',m3.bins[k]]);
  //   if (!costs['Model 3']) { costs['Model 3'] = 0; }
  //   costs['Model 3'] += m3.costs[k];
  // }
  // for (let k in my.bins) {
  //   localData.push([kHub,'Model Y',my.bins[k]]);
  //   if (!costs['Model Y']) { costs['Model Y'] = 0; }
  //   costs['Model Y'] += my.costs[k];
  // }
  // for (let k in wh.bins) {
  //   localData.push([kHub,'Water Heating',wh.bins[k]]);
  //   if (!costs['Water Heating']) { costs['Water Heating'] = 0; }
  //   costs['Water Heating'] += wh.costs[k];
  // }

  // subtract out the known incidentals from the house usage total
  // const accounted = [battery_import, my, wh];
  // let strippedLoad = stripBins(load, accounted);
  // for (let k in strippedLoad.bins) {
  //   localData.push([kHub,' '+k,strippedLoad.bins[k]]);
  //   // threeTier.push([kHub,kUsage/* + ' ' + k*/,strippedLoad.bins[k]]);
  //   if (!costs['Load']) { costs['Load'] = 0; }
  //   costs['Load'] += strippedLoad.costs[k];
  //   if (!costs[k]) { costs[k] = 0; }
  //   costs[k] += strippedLoad.costs[k];
  // }

  const unified = {};
  for (let i=0;i<localData.length;i++) {
    const [f,t,q] = localData[i];
    if (!unified[f]) {
      unified[f] = {};
    }
    if (!unified[f][t]) {
      unified[f][t] = 0;
    }
    unified[f][t] += q;
  }

  const rolled = [];
  for (let k in unified) {
    for (let j in unified[k]) {
      rolled.push([k,j,unified[k][j]]);
    }
  }
  // console.log(localData);
  // console.log(site);
  // console.log(unified,rolled);

  const annotated_costs = {};
  Object.keys(costs).map(k => annotated_costs[k] = `$${costs[k].toFixed(2)}`);
  const dressedLocalData = [localDataHeader];
  for (let i=0;i<rolled.length;i++)  {
    let k0 = rolled[i][0];
    let k1 = rolled[i][1];
    if (annotated_costs[k0]) {
      k0 = k0 + ' ' + annotated_costs[k0];
    }
    if (annotated_costs[k1]) {
      k1 = k1 + ' ' + annotated_costs[k1];
    }
    dressedLocalData.push([k0,k1,parseFloat(rolled[i][2].toFixed(1))]);
  }
// console.log('original',localData);
// console.log('dressed',dressedLocalData);
  // let ins = 0;
  // let outs = 0;

  let all = [battery_import, wh, solar_export, load];
  let loaded = localData.length > 1 && all.every(r=>r.loaded);
  loaded = true;
  return (
    <div className='app' style={{margin:20,height:450,width:1216}}>
      {/* <WaterFlow /> */}
      {/* <PowerFlow /> */}
      <button style={{width:'10vw',margin:20,fontSize:18,backgroundColor:'white'}} onClick={() => setState(!state)}>{state?'Last 24 hours':'Just today'}</button>
      {loaded?(<>
      <Chart
        chartType='Sankey'
        width='100%'
        height='400px'
        data={dressedLocalData.filter((r,i) => i===0 || r[2]>0)}
        // data={threeTier.filter((r,i) => i===0 || r[2]>0)}
        options={options}
      /></>):<img style={{position:'fixed',width:'20vw',height:'20vw',left:'40vw',top:'20vh'}} src={spinner} />
}
    </div>
  );
}

// function WaterFlow() {
//   const [state,setState] = useState(true);
//   // const site = useRateBins('sensor.powerwall_site_import');
//   const hot_water = useHistory('sensor.mechanical_room_water_meter_total_dispensed_hot_water',2);
//   const cold_water = useHistory('sensor.sensus_water_meter_gallons_2',2);
//   const sprinklers = useHistory('switch.water_all_zones_schedule',2);
//   // console.log({hot_water,cold_water,sprinklers});
// 
//   let sprinkler_runs = [];
//   for (let i=0;i<sprinklers.length;i++) {
//     if (sprinklers[i].state === 'on') {
//       sprinkler_runs.push([(new Date(sprinklers[i].last_changed)).getTime(), (new Date()).getTime()]);
//     }
//     if (sprinklers[i].state === 'off' && sprinkler_runs.length) {
//       sprinkler_runs[sprinkler_runs.length - 1][1] = (new Date(sprinklers[i].last_changed)).getTime();
//     }
//   }
//   const cold = cold_water
//     .filter(r => r.state !== 'unavailable')
//     .map(r => [(new Date(r.last_changed)).getTime(),parseFloat(r.state)]);
//   const hot = hot_water
//     .filter(r => r.state !== 'unavailable')
//     .map(r => [(new Date(r.last_changed)).getTime(),parseFloat(r.state)]);
// 
//   // TODO - this is kind of wrong. Should instead find reset-to-zero evernts
//   // and add togerther all the just-before-then values;
//   // as these are stateful accumulators which reset when the host device power cycles.
// 
//   let sprinkler_usage = 0;
//   for (let i=0;i<sprinkler_runs.length;i++) {
//     for (let j=0;j<cold.length-1;j++) {
//       if (cold[j][0] >= sprinkler_runs[i][0] && cold[j+1][0] <= sprinkler_runs[i][1]) {
//         if (cold[j+1][1] > 0){
//           sprinkler_usage += cold[j+1][1];
//           sprinkler_usage -= cold[j][1];
//         } else {
//           sprinkler_usage += cold[j][1];
//         }
//       }
//     }
//   }
//   // sprinkler_usage *= 0.26;
// 
//   // in L, convert to gallons
//   // console.log('cold span',cold[0],cold[cold.length-1]);
//   // console.log('hot span',hot[0],hot[hot.length-1]);
//   // console.log({cold,hot,sprinkler_runs,sprinkler_usage});
//   return null;
// //
// //
// //
// 
// }



export {
  EnergyFlow,
  PowerDiagram,
  EnergyDiagram,
  useRateBins,
};